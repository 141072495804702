import { api } from '../api/index';
import { useQuery } from '@tanstack/react-query';
import useBetList from '../v2/hooks/useBetList';
import { GLOBAL_API_REFETCH_INTERVAL, GLOBAL_API_STALE_TIME, GlobalQuery } from './config';
import useUserInfo from '../v2/hooks/useUserInfo';
import QueryFunctions from './query-fns';
import { QueryDataTypes } from '../types';

/**
 * Fetches the list of user bets from the server.
 *
 * @returns {{
 *   refetch: () => void,
 *   data: QueryDataTypes.UserBetsData | undefined,
 *   error: string | undefined,
 *   isLoading: boolean,
 *   isError: boolean,
 *   isSuccess: boolean,
 * }} - The query object containing refetch, data, error, and isLoading.
 */
export const GetUserBets = () => {
  const { currentBettingAccountId } = useUserInfo();
  const { onSetBetList } = useBetList();

  const { data, isError, error, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [GlobalQuery.UserBets, currentBettingAccountId],
    queryFn: () => QueryFunctions.fetchUserbets(currentBettingAccountId),
    staleTime: GLOBAL_API_STALE_TIME,
    refetchInterval: GLOBAL_API_REFETCH_INTERVAL,
    enabled: !!currentBettingAccountId
  });

  if (data?.data?.bets) {
    onSetBetList(data.data.bets);
  }

  return {
    data: data?.data?.bets,
    error: error?.response?.data?.message,
    refetch,
    isError,
    isSuccess,
    isLoading
  };
};
