import React from 'react';
import { useState, useEffect } from 'react';

const useAnalyticsData = (analyticsData, fromDate) => {
  const [chartData, setChartData] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  useEffect(() => {
    const generateChartData = () => {
      if (!analyticsData?.running_pnl?.length) {
        console.error('No data available for running_pnl');
        setChartData([]);
        setYAxis([0]);
        return;
      }
      const pnlValues = analyticsData.running_pnl.map(item => item.running_pnl);

      // Ensure there are valid numbers
      if (!pnlValues.length) {
        console.error('No valid running_pnl values found');
        return;
      }

      const maxPnl = Math.max(...pnlValues);
      const minPnl = Math.min(...pnlValues);

      // Determine the absolute maximum value to create a symmetric range
      const absMax = Math.max(Math.abs(maxPnl), Math.abs(minPnl));
      const absMin = Math.min(Math.abs(maxPnl), Math.abs(minPnl));

      // Generate Y-axis values
      const step = 10; // Adjust step size for finer control
      const yAxis = [];

      // Add positive values
      for (let i = absMax; i > 0; i -= step) {
        yAxis.push(i);
      }

      // Add the static 0 value in the middle
      yAxis.push(0);

      // Add negative values
      for (let i = -absMin; i >= -absMax; i -= step) {
        yAxis.push(i);
      }

      setYAxis(yAxis); // Store Y-axis values in state

      // Extract unique hours
      const uniqueHours = Array.from(
        new Set(
          analyticsData.running_pnl.map(item => {
            const date = new Date(item.time); // Assuming `time` is in ISO format
            return date.getHours(); // Extract the hour
          })
        )
      );

      // Sort hours in ascending order
      uniqueHours.sort((a, b) => a - b);

      // Format chart data
      const chartData = uniqueHours.map(hour => {
        const hourString = hour < 10 ? `0${hour}:00` : `${hour}:00`;

        // Find the running_pnl value for this hour
        const pnlData = analyticsData.running_pnl.find(item => {
          const date = new Date(item.time);
          return date.getHours() === hour;
        });

        // If data exists for this hour, use it; otherwise, set to 0
        return {
          name: hourString,
          uv: pnlData ? pnlData.running_pnl : 0
        };
      });

      setChartData(chartData); // Store chart data in state
    };

    generateChartData(); // Call the function when the component mounts
  }, [analyticsData, fromDate]); // Re-run when `analyticsData` changes

  return { chartData, yAxis };
};

export default useAnalyticsData;
