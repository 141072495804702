import React from 'react';

const MinusCircleRed = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.9998 14.0003C18.9998 14.2213 18.912 14.4333 18.7558 14.5896C18.5995 14.7459 18.3875 14.8337 18.1665 14.8337H9.83318C9.61216 14.8337 9.4002 14.7459 9.24392 14.5896C9.08764 14.4333 8.99984 14.2213 8.99984 14.0003C8.99984 13.7793 9.08764 13.5674 9.24392 13.4111C9.4002 13.2548 9.61216 13.167 9.83318 13.167H18.1665C18.3875 13.167 18.5995 13.2548 18.7558 13.4111C18.912 13.5674 18.9998 13.7793 18.9998 14.0003ZM24.8332 14.0003C24.8332 16.143 24.1978 18.2375 23.0074 20.019C21.817 21.8005 20.1251 23.1891 18.1456 24.009C16.166 24.829 13.9878 25.0435 11.8864 24.6255C9.7849 24.2075 7.85459 23.1757 6.33952 21.6607C4.82445 20.1456 3.79267 18.2153 3.37467 16.1138C2.95666 14.0123 3.1712 11.8341 3.99115 9.85459C4.8111 7.87506 6.19963 6.18312 7.98116 4.99274C9.7627 3.80236 11.8572 3.16699 13.9998 3.16699C16.8721 3.17003 19.6258 4.31237 21.6568 6.34335C23.6878 8.37434 24.8301 11.1281 24.8332 14.0003ZM23.1665 14.0003C23.1665 12.1873 22.6289 10.415 21.6216 8.9076C20.6144 7.40015 19.1828 6.22523 17.5078 5.53143C15.8328 4.83763 13.9897 4.6561 12.2115 5.00979C10.4334 5.36349 8.80001 6.23653 7.51803 7.51851C6.23605 8.8005 5.36301 10.4338 5.00931 12.212C4.65561 13.9902 4.83714 15.8333 5.53095 17.5083C6.22475 19.1832 7.39966 20.6149 8.90711 21.6221C10.4146 22.6294 12.1868 23.167 13.9998 23.167C16.4301 23.1642 18.7601 22.1976 20.4786 20.4791C22.1971 18.7606 23.1638 16.4306 23.1665 14.0003Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default MinusCircleRed;
