import { createSelector } from 'reselect';

const userInfoState = state => state?.userInfo;

export const userInfoRedux = createSelector(userInfoState, state => state?.userInfo);
export const userBettingAccountsSelector = createSelector(
  userInfoState,
  state => state?.userInfo?.bettingAccounts
);
export const currentAccountSelector = createSelector(userInfoState, userInfo => {
  return userInfo?.userInfo?.bettingAccounts?.find(
    account => account?.id === userInfo?.currentBettingAccountId
  );
});

export const currentAccountIdSelector = createSelector(
  userInfoState,
  userInfo => userInfo?.currentBettingAccountId
);
