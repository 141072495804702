import React from 'react';
import { makeStyles } from '@material-ui/core';
import ProgressBar from '../../../components/ProgressBar';
import { formatNumberForCurrency } from '../../../utils/general';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1), // 4px
    padding: theme.spacing(2.5), // 20px
    width: '100%',
    borderRadius: '8px', // assuming 8px default => 12px
    background: theme.palette.custom.betButton,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // shadow-dashboard-icon-shadow
    border: `1px solid ${theme.palette.custom.inputBorder}`, // border-primary-iconBorder
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  iconContainer: {
    height: theme.spacing(6), // 48px
    width: theme.spacing(6), // 48px
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius * 2 // assuming 8px default => 16px
  },
  cardTitle: {
    color: theme.palette.custom.white, // text-primary-default
    fontSize: theme.typography.pxToRem(14) // text-sm
  },
  cardValue: {
    fontSize: theme.typography.pxToRem(20) // text-xl
  },
  cardValuePositive: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.success.main // or your specific green color
  },
  cardValueNegative: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.error.main // or your specific red color
  },
  cardValueNeutral: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.white
  }
}));

const DrawDownCard = ({ card }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.cardContainer}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
    >
      <div>
        <span className={classes.cardTitle} style={{ color: 'white' }}>
          {card?.title}
        </span>
      </div>
      <div
        style={{
          width: '100%',
          marginTop: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start'
        }}
      >
        {card?.showPercentage && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              width: '100%',
              gap: '4px'
            }}
          >
            <span className={classes.cardTitle}>
              {formatNumberForCurrency(card?.left)} Remaining
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '8px',
                width: '100%'
              }}
            >
              <ProgressBar
                height={6}
                percentage={card?.progress < 0 ? 0 : card?.progress}
                backgroundColor={'rgba(245, 245, 245, 0.5)'}
                accentColor={card?.percentageColor?.color}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <div className={classes.cardTitle} style={{ color: 'white' }}>
                {card?.progress}%
              </div>
              <div className={classes.cardTitle} style={{ color: 'white' }}>
                {formatNumberForCurrency(card?.value)}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DrawDownCard;
