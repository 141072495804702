const USER_INFO = 'user/USER_INFO';
const CURRENT_BETTING_ACCOUNT_ID = 'user/CURRENT_BETTING_ACCOUNT_ID';
const USER_ACCOUNTS = 'user/USER_ACCOUNTS';

const setUserInfo = info => ({ type: USER_INFO, userInfo: info });
const setCurrentBettingAccountId = bettingAccountId => ({
  type: CURRENT_BETTING_ACCOUNT_ID,
  currentBettingAccountId: bettingAccountId
});

const setUserAccounts = userAccounts => ({ type: USER_ACCOUNTS, userAccounts: userAccounts });

export {
  USER_INFO,
  CURRENT_BETTING_ACCOUNT_ID,
  USER_ACCOUNTS,
  setUserInfo,
  setCurrentBettingAccountId,
  setUserAccounts
};
