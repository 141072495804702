import React from 'react';

const InsightIcon = () => {
  return (
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1_21668)'>
        <path
          d='M14.4681 5.79407C13.4766 5.79407 12.9227 6.77877 13.1483 7.51046L10.7207 9.94488C10.5156 9.88333 10.2147 9.88333 10.0096 9.94488L8.26582 8.20113C8.49832 7.46944 7.94442 6.47789 6.94604 6.47789C5.95449 6.47789 5.39376 7.4626 5.62626 8.20113L2.50802 11.3125C1.77633 11.0869 0.791626 11.6408 0.791626 12.6323C0.791626 13.3845 1.40707 13.9999 2.15927 13.9999C3.15082 13.9999 3.70471 13.0152 3.47905 12.2836L6.59045 9.16532C6.7956 9.22686 7.09648 9.22686 7.30163 9.16532L9.04538 10.9091C8.81288 11.6408 9.36677 12.6323 10.3652 12.6323C11.3567 12.6323 11.9174 11.6476 11.6849 10.9091L14.1193 8.48149C14.851 8.70716 15.8357 8.15326 15.8357 7.16171C15.8357 6.40951 15.2203 5.79407 14.4681 5.79407Z'
          fill='currentColor'
        />
        <path
          d='M10.3652 6.47794L11.008 5.06243L12.4167 4.42647L11.008 3.79051L10.3652 2.375L9.73607 3.79051L8.31372 4.42647L9.73607 5.06243L10.3652 6.47794Z'
          fill='currentColor'
        />
        <path
          d='M2.50118 7.84557L2.8431 6.47792L4.21074 6.13601L2.8431 5.7941L2.50118 4.42645L2.15927 5.7941L0.791626 6.13601L2.15927 6.47792L2.50118 7.84557Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_21668'>
          <rect width='19' height='19' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InsightIcon;
