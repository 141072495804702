import { api } from '../api/index';
import { useQuery } from '@tanstack/react-query';
import { GLOBAL_API_REFETCH_INTERVAL, GLOBAL_API_STALE_TIME, GlobalQuery } from './config';
import useUserInfo from '../v2/hooks/useUserInfo';
import useTransactions from '../v2/hooks/useTransactions';
import { BetTypes, QueryDataTypes } from '../types';
import QueryFunctions from './query-fns';

/**
 * Fetches the list of user bets from the server.
 *
 * @returns {{
 *   refetch: () => void,
 *   data: QueryDataTypes.UserBettingTransactionData | undefined,
 *   error: string | undefined,
 *   isLoading: boolean,
 *   isError: boolean,
 *   isSuccess: boolean,
 * }} - The query object containing refetch, data, error, and isLoading.
 */
export const GetBettingAccountTransactions = () => {
  const { currentBettingAccountId } = useUserInfo();
  const { setTransactions } = useTransactions();

  const { data, isError, error, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [GlobalQuery.BettingAccountTransaction, currentBettingAccountId],
    queryFn: () => QueryFunctions.fetchUserBettingTransactions(currentBettingAccountId),
    staleTime: GLOBAL_API_STALE_TIME * 2,
    refetchInterval: GLOBAL_API_REFETCH_INTERVAL * 2 * 60, // 30sec * 2 * 60 > 1 Hour
    enabled: !!currentBettingAccountId
  });
  if (data?.data) {
    setTransactions(data?.data);
  }

  return {
    data: data?.data,
    error: error?.response?.data?.message,
    refetch,
    isError,
    isSuccess,
    isLoading
  };
};
