import React, { useState } from 'react';
import TopCard from './MyStats/TopCard';
import TradingResultChart from './MyStats/TradingResultChart';
import ArrowsDownUp from '../icons/ArrowsDownUp';
import MedalIcon from '../icons/MedalIcon';
import Medal from '../icons/Medal';
import ScalesIcon from '../icons/Scales';
import PlusCircle from '../icons/PlusCircle';
import { Button, makeStyles } from '@material-ui/core';
import ArrowUpGreen from '../icons/ArrowUpGreen';
import PlusCircleGreen from '../icons/PlusCircleGreen';
import TrendUp from '../icons/TrendUp';
import CalenderPlusGreen from '../icons/CalenderPlusGreen';
import ArrowDownRed from '../icons/ArrowDownRed';
import MinusCircleRed from '../icons/MinusCircleRed';
import TrendDownRed from '../icons/TrendDownRed';
import CalenderXRed from '../icons/CalenderXRed';
import ArrowMerge from '../icons/ArrowMerge';
import moment from 'moment';
import { GetBettingAccountTransactions } from '../../react-query/transactions';
import useUserInfo from '../hooks/useUserInfo';
import useAnalyticsData from '../hooks/useAnalyticsData';
import NewTable from './NewTable';
import { GetAccountStats, GetAllAccounts } from '../../react-query/user';
import { GetUserBets } from '../../react-query/bets';
import clsx from 'clsx';
import { formatNumberForCurrency } from '../../utils/general';
import DrawDownCard from './MyStats/DrawDownCard';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2.5), // 10px
    padding: theme.spacing(1.5), // 6px
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    backgroundColor: theme.palette.custom.black
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1), // 4px
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  chartContainer: {
    paddingTop: theme.spacing(4), // 8px
    paddingBottom: theme.spacing(4) // 8px
  },
  bottomSection: {
    paddingBottom: theme.spacing(4) // 8px
  },
  summaryContainer: {
    marginTop: theme.spacing(2.25), // 36px (mt-9)
    padding: 24, // 24px (p-6)
    borderRadius: theme.shape.borderRadius * 2, // 16px (rounded-2xl)
    background: theme.palette.custom.gray
  },
  heading: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.custom.white
  },
  gridContainer: {
    display: 'grid',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  summaryAnalyticsContainer: {
    display: 'grid',
    gap: theme.spacing(1), // 16px (gap-4)
    marginTop: theme.spacing(1.5), // 24px (mt-6)
    gridTemplateColumns: 'repeat(1, 1fr)', // grid-cols-1
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  summaryCardsContainer: {
    display: 'grid',
    gap: theme.spacing(1), // 16px (gap-4)
    paddingTop: theme.spacing(2), // 32px (pt-8)
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: 500,
    width: 'max-content',
    color: theme.palette.custom.white,
    [theme.breakpoints.down('md')]: {
      marginTop: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '10px'
    }
  },
  betTypeParlay: {
    background: 'rgba(237, 83, 99, 0.2)',
    color: theme.palette.error.main
  },
  betTypeSingle: {
    background: 'rgba(27, 191, 153, 0.18)',
    color: theme.palette.success.main
  },
  betTypeIndicatorParlay: {
    backgroundColor: theme.palette.error.main
  },
  betTypeIndicatorSingle: {
    backgroundColor: theme.palette.success.main
  },
  statusText: {
    textTransform: 'capitalize'
  },
  betTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    fontWeight: 600,
    fontSize: '0.75rem',
    borderRadius: '9999px',
    height: '20px',
    width: 'max-content',
    color: theme.palette.custom.white, // Normal color for all bet types
    background: theme.palette.custom.betButton // Or your dark background color
  },
  betTypeIndicator: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.custom.white // Normal color for all indicators
  },
  statusText: {
    textTransform: 'capitalize'
  },
  statusWon: {
    color: theme.palette.success.main
  },
  statusLost: {
    color: theme.palette.error.main
  },
  statusInPlay: {
    color: theme.palette.warning.main // Orange color for in-play
  },
  profitText: {
    textAlign: 'center'
  },
  profitPositive: {
    color: theme.palette.success.main
  },
  profitNegative: {
    color: theme.palette.error.main
  }
}));

const transformBetsForTable = bets => {
  if (!bets) return [];

  const transformBet = (bet, isParlay) => ({
    bet_id: bet.id,
    open_time: bet.created,
    open_price: bet.price,
    close_time: bet.game.start_time,
    close_price: '-',
    direction: 1,
    profit:
      bet?.result === 'in-play' ? '-' : bet?.result === 'winner' ? bet.winnings : -bet?.bet_amount,
    bet_type: isParlay ? 'Parlay' : 'Single',
    bet_amount: bet.bet_amount,
    selection: bet.name,
    match: `${bet.game.home_team} vs ${bet.game.away_team}`,
    status: bet.result,
    league: bet.game.league.name
  });

  const parlayBets = bets.parlayBets?.map(bet => transformBet(bet, true)) || [];
  const singleBets = bets.singleBets?.map(bet => transformBet(bet, false)) || [];

  // Combine and sort by date (newest first)
  return [...parlayBets, ...singleBets].sort((a, b) => {
    return new Date(b.open_time) - new Date(a.open_time);
  });
};

const MyStats = () => {
  const classes = useStyles();
  const [allTradeSummary, setAllTradeSummary] = useState([]);
  const [fromDate, setFromDate] = useState('03-09-2024');
  const [toDate, setToDate] = useState(null);
  const { currentBettingAccountId } = useUserInfo();

  const { data: tradeHistory } = GetBettingAccountTransactions(currentBettingAccountId);
  const { data: stats } = GetAccountStats();

  const { data: bets } = GetUserBets();
  const { data: challengesAccount } = GetAllAccounts();
  const { currentAccount } = useUserInfo();

  const generalStats = stats?.general_stats;
  const challengeStats = stats?.challenge_stats;
  const performanceStats = stats?.performance_stats;

  const [analyticsData, setAnalyticsData] = useState({
    running_pnl: [],
    //@ts-ignore
    stats: []
  });

  const { chartData, yAxis } = useAnalyticsData(analyticsData, fromDate);

  const deltaPct = 0.02;
  const minBalance =
    tradeHistory && tradeHistory.length > 0
      ? Math.min(...tradeHistory.map(item => Number(item.balance))) * (1 - deltaPct)
      : 0;
  const maxBalance =
    tradeHistory && tradeHistory.length > 0
      ? Math.max(...tradeHistory.map(item => Number(item.balance))) * (1 + deltaPct)
      : 1000;

  const handleSelectSlot = date => {
    setFromDate(moment(date).format('DD-MM-YYYY'));

    // Calculate the next day for "to" date
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    setToDate(moment(nextDay).format('DD-MM-YYYY'));
  };

  const getdrawdownStatsForCurrentAccount = challengesAccount?.find(
    account => account.user === currentAccount?.userId
  );
  const currentDrawdown = getdrawdownStatsForCurrentAccount?.drawdown_stats;

  const maximumDrawdownLeft = currentDrawdown?.maximum_drawdown?.left;
  const maximumDrawdownTotal = currentDrawdown?.maximum_drawdown?.total;
  const maximumDrawdownProgress = currentDrawdown?.maximum_drawdown?.pct_completed;

  const dailyDrawdownLeft = currentDrawdown?.daily_drawdown?.left;
  const dailyDrawdownTotal = currentDrawdown?.daily_drawdown?.total;
  const dailyDrawdownProgress = currentDrawdown?.daily_drawdown?.pct_completed;

  const targetProfitLeft = currentDrawdown?.profit_target?.left;
  const targetProfitTotal = currentDrawdown?.profit_target?.total;
  const targetProfitProgress = currentDrawdown?.profit_target?.pct_completed;

  const topCards = [
    {
      title: 'Net P&L',
      icon: ArrowsDownUp,
      value: generalStats?.pnl,
      type: 'currency'
    },
    {
      title: 'Bet Win rate',
      icon: Medal,
      value: generalStats?.win_rate,
      type: 'percentage'
    },
    {
      title: 'Average Realized R:R',
      icon: ScalesIcon,
      value: performanceStats?.average_rrr
    },
    {
      title: 'Profit Factor',
      icon: PlusCircle,
      value: performanceStats?.profit_factor
    }
  ];

  const drawdownStats = [
    {
      title: 'Daily Drawdown',
      value: `${dailyDrawdownTotal?.toFixed(2) || 0}`,
      progress: dailyDrawdownProgress?.toFixed(2) || 0,
      left: dailyDrawdownLeft,
      showPercentage: true,
      percentageColor: { color: '#FF0000' } // Red hex code
    },
    {
      title: 'Maximum Drawdown',
      value: `${maximumDrawdownTotal?.toFixed(2) || 0}`,
      progress: maximumDrawdownProgress?.toFixed(2) || 0,
      left: maximumDrawdownLeft,
      showPercentage: true,
      percentageColor: { color: '#FF0000' } // Red hex code
    },
    {
      title: 'Target Profit',
      value: `${targetProfitTotal?.toFixed(2) || 0}`,
      progress: targetProfitProgress?.toFixed(2) || 0,
      left: targetProfitLeft,
      showPercentage: true,
      percentageColor: { color: '#1F6021' } // Red hex code
    }
  ];

  const bottomCards = [
    {
      title: 'Total Winners',
      icon: PlusCircleGreen,
      value: performanceStats?.total_winners,
      defaultType: 'positive'
    },
    {
      title: 'Best Win',
      icon: ArrowUpGreen,
      value: performanceStats?.best_win,
      defaultType: 'positive',
      type: 'currency'
    },
    {
      title: 'Avg. Win',
      icon: TrendUp,
      value: performanceStats?.avg_win,
      defaultType: 'positive',
      type: 'currency'
    },
    {
      title: 'Max. Win Streak',
      icon: CalenderPlusGreen,
      value: performanceStats?.max_win_streak,
      defaultType: 'positive'
    }
  ];

  // const picksStats = [
  //   {
  //     icon: DocIcon,
  //     title: 'Total Picks',
  //     value: challengeStats?.total_picks || 0
  //   },
  //   {
  //     icon: InsightIcon,
  //     title: 'Winning Picks',
  //     value: challengeStats?.winning_picks || 0
  //   },
  //   {
  //     icon: PlayIcon,
  //     title: 'Picks in Play',
  //     value: challengeStats?.picks_in_play || 0
  //   },
  //   {
  //     icon: CheckCircle,
  //     title: 'ROI',
  //     value: generalStats?.roi,
  //     type: 'percentage'
  //   },
  //   {
  //     icon: Money,
  //     title: 'Bankroll',
  //     value: generalStats?.live_balance,
  //     type: 'currency'
  //   }
  // ];

  const bottomCards2 = [
    {
      title: 'Total Losers',
      icon: MinusCircleRed,
      value: performanceStats?.total_losers,
      defaultType: 'negative'
    },
    {
      title: 'Worst Loss',
      icon: ArrowDownRed,
      value: performanceStats?.worst_loss,
      type: 'currency',
      defaultType: 'negative'
    },
    {
      title: 'Avg. Loss',
      icon: TrendDownRed,
      value: performanceStats?.avg_loss,
      type: 'currency',
      defaultType: 'negative'
    },
    {
      title: 'Max. Loss Streak',
      icon: CalenderXRed,
      value: performanceStats?.max_loss_streak,
      defaultType: 'negative'
    }
  ];

  const summaryCards = [
    {
      title: 'P&L',
      icon: ArrowsDownUp,
      value: generalStats?.pnl,
      type: 'currency'
    },
    {
      title: 'Best Day',
      icon: CalenderPlusGreen,
      value: performanceStats?.best_day?.date || 'N/A'
    },
    {
      title: 'Worst Day',
      icon: CalenderXRed,
      value: performanceStats?.worst_day?.date || 'N/A'
    },
    {
      title: 'Win Rate',
      icon: ArrowMerge,
      value: generalStats?.win_rate,
      type: 'percentage'
    }
  ];

  const columns = [
    {
      name: 'Bet Id',
      key: 'bet_id',
      minWidth: 80
    },
    {
      name: 'Date',
      key: 'open_time',
      minWidth: 120,
      renderCell: props => <div>{moment.utc(props.open_time).format("MMM DD'YY HH:mm:ss")}</div>
    },
    {
      name: 'League',
      key: 'league',
      minWidth: 120
    },
    {
      name: 'Match',
      key: 'match',
      minWidth: 180
    },
    {
      name: 'Selection',
      key: 'selection',
      minWidth: 100
    },
    {
      name: 'Type',
      key: 'bet_type',
      minWidth: 80,
      renderCell: props => <div className={classes.betTypeContainer}>{props.bet_type}</div>
    },
    {
      name: 'Status',
      key: 'status',
      minWidth: 100,
      renderCell: props => (
        <div
          className={clsx(classes.statusText, {
            [classes.statusWon]: props.status?.toLowerCase() === 'winner',
            [classes.statusLost]: props.status?.toLowerCase() === 'loser',
            [classes.statusInPlay]: props.status?.toLowerCase() === 'in-play'
          })}
        >
          {props.status === 'winner' ? 'WON' : props.status === 'loser' ? 'LOST' : 'IN PLAY'}
        </div>
      )
    },
    {
      name: 'Stake',
      key: 'bet_amount',
      minWidth: 100,
      renderCell: props => (
        <div style={{ minWidth: 'max-content' }}>{formatNumberForCurrency(props.bet_amount)}</div>
      )
    },
    {
      name: 'Profit',
      key: 'profit',
      minWidth: 100,
      renderCell: props => {
        const profit = Number(props.profit);
        return (
          <div
            style={{ minWidth: 'max-content' }}
            className={clsx(
              classes.profitText,
              profit > 0 ? classes.profitPositive : classes.profitNegative
            )}
          >
            {formatNumberForCurrency(props.profit)}
          </div>
        );
      }
    }
  ];

  return (
    <div style={{ width: '100%', marginTop: '20px', overflowY: 'scroll', paddingBottom: '200px' }}>
      <div className={classes.pageTitle}>Bet Stats</div>
      <div className={classes.container}>
        <div className={classes.gridContainer}>
          {topCards?.map(card => (
            <TopCard card={card} />
          ))}
        </div>
        <div className={classes.gridContainer}>
          {/* {picksStats?.map(card => ( */}
          {/*   <TopCard card={card} /> */}
          {/* ))} */}
          {drawdownStats?.map(card => (
            <DrawDownCard card={card} />
          ))}
        </div>
        <div className={classes.chartContainer}>
          <TradingResultChart
            tradeHistory={tradeHistory}
            minBalance={minBalance}
            maxBalance={maxBalance}
          />
        </div>
        <div className={classes.bottomSection}>
          <div className={classes.gridContainer}>
            {bottomCards?.map(card => (
              <TopCard card={card} />
            ))}
          </div>
        </div>
        <div>
          <div className={classes.gridContainer}>
            {bottomCards2?.map(card => (
              <TopCard card={card} />
            ))}
          </div>
        </div>
      </div>

      {/* <div className={classes.summaryContainer}> */}
      {/*   <span className={classes.heading}>Summary by the day</span> */}
      {/*   <div className={classes.summaryAnalyticsContainer}> */}
      {/*     <Calender onChange={handleSelectSlot} fromDate={fromDate} /> */}
      {/*     <TradeChart */}
      {/*       chartData={chartData} */}
      {/*       yAxis={yAxis} */}
      {/*       analyticsData={analyticsData} */}
      {/*       fromDate={fromDate} */}
      {/*     /> */}
      {/*   </div> */}
      {/*   <div className={classes.summaryCardsContainer}> */}
      {/*     {summaryCards?.map(card => ( */}
      {/*       <TopCard card={card} /> */}
      {/*     ))} */}
      {/*   </div> */}
      {/* </div> */}

      <div style={{ margin: '20px 0px' }}>
        <NewTable title='Bet History' columns={columns} data={transformBetsForTable(bets || [])} />
      </div>
    </div>
  );
};

export default MyStats;
