import React from 'react';

const PlusCircle = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.2498 3.16699C12.1072 3.16699 10.0127 3.80236 8.23116 4.99274C6.44963 6.18312 5.0611 7.87506 4.24115 9.85459C3.4212 11.8341 3.20666 14.0123 3.62467 16.1138C4.04267 18.2153 5.07445 20.1456 6.58952 21.6607C8.10459 23.1757 10.0349 24.2075 12.1364 24.6255C14.2378 25.0435 16.416 24.829 18.3956 24.009C20.3751 23.1891 22.067 21.8005 23.2574 20.019C24.4478 18.2375 25.0832 16.143 25.0832 14.0003C25.0801 11.1281 23.9378 8.37434 21.9068 6.34335C19.8758 4.31237 17.1221 3.17003 14.2498 3.16699ZM14.2498 23.167C12.4368 23.167 10.6646 22.6294 9.15711 21.6221C7.64966 20.6149 6.47475 19.1832 5.78095 17.5083C5.08714 15.8333 4.90561 13.9902 5.25931 12.212C5.61301 10.4338 6.48605 8.8005 7.76803 7.51851C9.05001 6.23653 10.6834 5.36349 12.4615 5.00979C14.2397 4.6561 16.0828 4.83763 17.7578 5.53143C19.4328 6.22523 20.8644 7.40015 21.8716 8.9076C22.8789 10.415 23.4165 12.1873 23.4165 14.0003C23.4138 16.4306 22.4471 18.7606 20.7286 20.4791C19.0101 22.1976 16.6801 23.1642 14.2498 23.167ZM19.2498 14.0003C19.2498 14.2213 19.162 14.4333 19.0058 14.5896C18.8495 14.7459 18.6375 14.8337 18.4165 14.8337H15.0832V18.167C15.0832 18.388 14.9954 18.6 14.8391 18.7562C14.6828 18.9125 14.4709 19.0003 14.2498 19.0003C14.0288 19.0003 13.8169 18.9125 13.6606 18.7562C13.5043 18.6 13.4165 18.388 13.4165 18.167V14.8337H10.0832C9.86216 14.8337 9.6502 14.7459 9.49392 14.5896C9.33764 14.4333 9.24984 14.2213 9.24984 14.0003C9.24984 13.7793 9.33764 13.5674 9.49392 13.4111C9.6502 13.2548 9.86216 13.167 10.0832 13.167H13.4165V9.83366C13.4165 9.61265 13.5043 9.40068 13.6606 9.2444C13.8169 9.08812 14.0288 9.00033 14.2498 9.00033C14.4709 9.00033 14.6828 9.08812 14.8391 9.2444C14.9954 9.40068 15.0832 9.61265 15.0832 9.83366V13.167H18.4165C18.6375 13.167 18.8495 13.2548 19.0058 13.4111C19.162 13.5674 19.2498 13.7793 19.2498 14.0003Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PlusCircle;
