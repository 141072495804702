import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentBettingAccountId,
  setUserAccounts,
  setUserInfo
} from '../redux/userInfo/actions';
import {
  currentAccountIdSelector,
  currentAccountSelector,
  userBettingAccountsSelector,
  userInfoRedux
} from '../redux/userInfo/selector';

/**
 * @typedef {Object} Stats
 * @property {number} id - Unique identifier for the stats record
 * @property {number} accountId - ID of the associated betting account
 * @property {number} totalPicks - Total number of picks made
 * @property {number} totalWinningPicks - Number of winning picks
 * @property {number} totalLosingPicks - Number of losing picks
 * @property {number} totalPicksInPlay - Number of picks currently in play
 * @property {number | null} winRate - Win rate percentage
 * @property {number} roi - Return on investment percentage
 * @property {number} netProfits - Total net profits
 * @property {number} availableDrawdown - Remaining drawdown amount
 * @property {boolean} accountActive - Whether account is active
 * @property {number} currentPhase - Current phase number
 * @property {string} phaseDrawdown - Phase drawdown limit as decimal string
 * @property {string} phaseTarget - Phase profit target as decimal string
 * @property {string} createdAt - Creation timestamp
 * @property {string} updatedAt - Last update timestamp
 */

/**
 * @typedef {Object} BettingAccount
 * @property {number} id - Unique identifier for the account
 * @property {number} userId - ID of the account owner
 * @property {string} name - Account name
 * @property {boolean} active - Whether account is active
 * @property {number} drawdown - Current drawdown amount
 * @property {boolean} isFunded - Whether account is funded
 * @property {number} target - Profit target amount
 * @property {string} challengeId - ID of associated challenge
 * @property {number} currentPhase - Current phase number
 * @property {number} totalPhases - Total number of phases
 * @property {boolean} isAwaitingConfirmation - Whether awaiting confirmation
 * @property {string} createdAt - Creation timestamp
 * @property {string} updatedAt - Last update timestamp
 * @property {Stats} stats - Account statistics
 * @property {number} initial_balance - Starting balance amount
 * @property {number} min_bet_amount - Minimum allowed bet
 * @property {number} max_bet_amount - Maximum allowed bet
 */

/**
 * @typedef {Object} UserInfo
 * @property {string} firstName - User's first name
 * @property {string} lastName - User's last name
 * @property {string} email - User's email address
 * @property {BettingAccount[]} bettingAccounts - User's betting accounts
 */

/**
 * Custom hook to handle user information and betting accounts.
 *
 * @returns {{
 *   onSetUserInfo: (user: UserInfo) => void,
 *   setCurBettingAccountId: (id: number) => void,
 *   userInfo: UserInfo,
 *   currentBettingAccountId: number | null,
 *   currentAccount: BettingAccount | undefined,
 *   bettingAccounts: BettingAccount[]
 *   setUserAccounts: (userAccounts: any) => void
 * }} - An object with user information and methods to manage it.
 */
function useUserInfo() {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoRedux);
  const currentBettingAccountId = useSelector(currentAccountIdSelector);
  const currentAccount = useSelector(currentAccountSelector);
  const bettingAccounts = useSelector(userBettingAccountsSelector);

  const handleUserInfo = user => {
    dispatch(setUserInfo(user));
  };

  const setCurBettingAccountId = id => {
    dispatch(setCurrentBettingAccountId(id));
  };

  const __setUserAccounts = userAccounts => {
    dispatch(setUserAccounts(userAccounts));
  };

  return {
    onSetUserInfo: handleUserInfo,
    setCurBettingAccountId,
    setUserAccounts: __setUserAccounts,
    userInfo,
    currentBettingAccountId,
    currentAccount,
    bettingAccounts
  };
}

export default useUserInfo;
