import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import './login-styles.css';
import Logo from '../../v2/components/Logo';
import { styled } from '@mui/material/styles';
import theme, { myTheme } from '../../v2/theme';
import { useSendResetPasswordLink } from '../../react-query/user';
import { errorMessage, successMessage } from '../../helpers/Utils';

const Container = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#15151A'
}));

const LoginBox = styled('div')(({ theme }) => ({
  backgroundColor: '#1f1f1f',
  borderRadius: 10,
  minWidth: 356,
  padding: theme.spacing(4)
}));

const InnerBox = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  border: '1px solid #2a2a2a',
  borderRadius: 10,
  padding: theme.spacing(4)
}));

const ForgotPassword = ({ onStateChange, loginUser, loading, error }) => {
  const { mutate } = useSendResetPasswordLink();
  const [isLinkSent, setIsLinkSent] = useState(false);

  const handleResetPassword = async values => {
    const { email } = values;

    try {
      mutate(email, {
        onSuccess: data => {
          successMessage('Please check your email for a password reset link');
          setIsLinkSent(true);
        },
        onError: data => {
          errorMessage('Please try again');
        }
      });
    } catch (error) {
      console.log('⏩ error:', error);
    }
  };

  if (isLinkSent) {
    return (
      <Container>
        <LoginBox>
          <InnerBox>
            <div style={{ textAlign: 'center', marginBottom: 24 }}>
              <Logo />
            </div>
            <Typography
              variant='h5'
              style={{ color: '#fff', textAlign: 'center', marginBottom: 16 }}
            >
              Check your email
            </Typography>
            <Typography
              variant='body1'
              style={{ color: '#ccc', textAlign: 'center', marginBottom: 24 }}
            >
              We’ve sent a password reset link to your email address. Please check your inbox and
              follow the instructions.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              style={{
                borderRadius: 10,
                width: '100%',
                background: theme(myTheme).palette.primary.main
              }}
              size='medium'
              onClick={() => (window.location.href = '/user/login')}
            >
              <a href='/user/login' style={{ color: 'white' }}>
                Back to Login
              </a>
            </Button>
          </InnerBox>
        </LoginBox>
      </Container>
    );
  }

  return (
    <Container>
      <LoginBox>
        <InnerBox>
          <div style={{ textAlign: 'center', marginBottom: 24 }}>
            <Logo />
          </div>
          <Typography
            variant='h5'
            style={{ color: '#fff', textAlign: 'center', marginBottom: 16 }}
          >
            Reset your password
          </Typography>
          <Formik initialValues={{ email: '' }} onSubmit={handleResetPassword}>
            {() => (
              <Form>
                <Typography color='white'>Email</Typography>
                <Field
                  component={ReactstrapFormikInput}
                  type='email'
                  name='email'
                  required
                  style={{ marginBottom: 16 }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 16
                  }}
                >
                  <a href='/user/login' style={{ color: 'white' }}>
                    Back to Login
                  </a>
                </div>

                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  style={{
                    borderRadius: 10,
                    width: '100%',
                    background: theme(myTheme).palette.primary.main,
                    marginTop: 30
                  }}
                  size='medium'
                  type='submit'
                  disabled={loading}
                >
                  Send reset password link
                </Button>
              </Form>
            )}
          </Formik>
        </InnerBox>
      </LoginBox>
    </Container>
  );
};

export default ForgotPassword;
