import React from 'react';

const ArrowMerge = () => {
  return (
    <div>
      <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M20.9163 4.83333V11.5C20.9164 11.6095 20.8949 11.7179 20.8531 11.819C20.8113 11.9202 20.75 12.0121 20.6726 12.0896L15.083 17.6781V22.8219L16.9934 20.9104C17.1498 20.754 17.3619 20.6662 17.583 20.6662C17.8041 20.6662 18.0162 20.754 18.1726 20.9104C18.329 21.0668 18.4168 21.2789 18.4168 21.5C18.4168 21.7211 18.329 21.9332 18.1726 22.0896L14.8393 25.4229C14.7619 25.5004 14.67 25.5619 14.5688 25.6038C14.4676 25.6457 14.3592 25.6673 14.2497 25.6673C14.1402 25.6673 14.0317 25.6457 13.9306 25.6038C13.8294 25.5619 13.7375 25.5004 13.6601 25.4229L10.3268 22.0896C10.1704 21.9332 10.0825 21.7211 10.0825 21.5C10.0825 21.2789 10.1704 21.0668 10.3268 20.9104C10.4831 20.754 10.6952 20.6662 10.9163 20.6662C11.1375 20.6662 11.3496 20.754 11.5059 20.9104L13.4163 22.8219V17.6781L7.82676 12.0896C7.7494 12.0121 7.68805 11.9202 7.64623 11.819C7.6044 11.7179 7.58292 11.6095 7.58301 11.5V4.83333C7.58301 4.61232 7.67081 4.40036 7.82709 4.24408C7.98337 4.0878 8.19533 4 8.41634 4C8.63736 4 8.84932 4.0878 9.0056 4.24408C9.16188 4.40036 9.24967 4.61232 9.24967 4.83333V11.1552L14.2497 16.1552L19.2497 11.1552V4.83333C19.2497 4.61232 19.3375 4.40036 19.4938 4.24408C19.65 4.0878 19.862 4 20.083 4C20.304 4 20.516 4.0878 20.6723 4.24408C20.8285 4.40036 20.9163 4.61232 20.9163 4.83333Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

export default ArrowMerge;
