import React from 'react';

const PlusCircleGreen = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.9998 3.16699C11.8572 3.16699 9.7627 3.80236 7.98116 4.99274C6.19963 6.18312 4.8111 7.87506 3.99115 9.85459C3.1712 11.8341 2.95666 14.0123 3.37467 16.1138C3.79267 18.2153 4.82445 20.1456 6.33952 21.6607C7.85459 23.1757 9.7849 24.2075 11.8864 24.6255C13.9878 25.0435 16.166 24.829 18.1456 24.009C20.1251 23.1891 21.817 21.8005 23.0074 20.019C24.1978 18.2375 24.8332 16.143 24.8332 14.0003C24.8301 11.1281 23.6878 8.37434 21.6568 6.34335C19.6258 4.31237 16.8721 3.17003 13.9998 3.16699ZM13.9998 23.167C12.1868 23.167 10.4146 22.6294 8.90711 21.6221C7.39966 20.6149 6.22475 19.1832 5.53095 17.5083C4.83714 15.8333 4.65561 13.9902 5.00931 12.212C5.36301 10.4338 6.23605 8.8005 7.51803 7.51851C8.80001 6.23653 10.4334 5.36349 12.2115 5.00979C13.9897 4.6561 15.8328 4.83763 17.5078 5.53143C19.1828 6.22523 20.6144 7.40015 21.6216 8.9076C22.6289 10.415 23.1665 12.1873 23.1665 14.0003C23.1638 16.4306 22.1971 18.7606 20.4786 20.4791C18.7601 22.1976 16.4301 23.1642 13.9998 23.167ZM18.9998 14.0003C18.9998 14.2213 18.912 14.4333 18.7558 14.5896C18.5995 14.7459 18.3875 14.8337 18.1665 14.8337H14.8332V18.167C14.8332 18.388 14.7454 18.6 14.5891 18.7562C14.4328 18.9125 14.2209 19.0003 13.9998 19.0003C13.7788 19.0003 13.5669 18.9125 13.4106 18.7562C13.2543 18.6 13.1665 18.388 13.1665 18.167V14.8337H9.83318C9.61216 14.8337 9.4002 14.7459 9.24392 14.5896C9.08764 14.4333 8.99984 14.2213 8.99984 14.0003C8.99984 13.7793 9.08764 13.5674 9.24392 13.4111C9.4002 13.2548 9.61216 13.167 9.83318 13.167H13.1665V9.83366C13.1665 9.61265 13.2543 9.40068 13.4106 9.2444C13.5669 9.08812 13.7788 9.00033 13.9998 9.00033C14.2209 9.00033 14.4328 9.08812 14.5891 9.2444C14.7454 9.40068 14.8332 9.61265 14.8332 9.83366V13.167H18.1665C18.3875 13.167 18.5995 13.2548 18.7558 13.4111C18.912 13.5674 18.9998 13.7793 18.9998 14.0003Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PlusCircleGreen;
