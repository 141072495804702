import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { formatNumberWithNegativeSign } from '../../utils';
import theme, { getPrimaryColor } from '../../theme';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.custom.inputBorder}`,
    background: theme.palette.custom.betButton
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5) // 20px
  },
  title: {
    color: theme.palette.custom.white, // text-primary
    fontWeight: theme.typography.fontWeightMedium, // font-medium
    fontSize: theme.typography.pxToRem(18), // text-lg
    paddingBottom: theme.spacing(5) // 40px (pb-10)
  }
}));

const TradingResultChart = ({ tradeHistory, minBalance, maxBalance }) => {
  const classes = useStyles();
  return (
    <div className={classes.chartContainer}>
      <div className={classes.header}>
        <div className={classes.title}>Bet results</div>
      </div>
      <ResponsiveContainer width='100%' height={400}>
        <LineChart
          width={500}
          height={300}
          data={tradeHistory?.map(item => ({
            timestamp: moment(item.timestamp).format('DD MMM YYYY'),
            balance: Number(item?.balance)
          }))}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='10 3' vertical={false} />
          <XAxis fontSize={12} dataKey='timestamp' />
          <YAxis
            fontSize={12}
            color='#fff'
            domain={[minBalance, maxBalance]}
            tickFormatter={item => {
              return formatNumberWithNegativeSign(item);
            }}
          />
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Legend />
          <Line
            type='monotone'
            dataKey='balance'
            stroke={getPrimaryColor()}
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TradingResultChart;
