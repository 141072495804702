import _ from 'lodash';

export const Client = {
  BETBIGGER: 'betbigger',
  MYSPORTSFUNDING: 'mysportsfunding',
  THEFUNDEDPICK: 'thefundedpick',
  SPORTSFUNDED: 'sportsfunded',
  BETVAULT: 'betvault'
};

export const categorizeAvailableSports = availableSports => {
  let result = {};
  if (!availableSports) return;

  availableSports = availableSports?.map(item => {
    return {
      title: item?.name,
      league: item?.key,
      sport: item?.sport
    };
  });

  availableSports.forEach(sport => {
    const sportCategory = sport?.sport || '';

    if (!sportCategory) return;

    if (result[sportCategory]) {
      result[sportCategory].leagues.push(sport);
    } else {
      result[sportCategory] = {
        leagues: [sport],
        title: sportCategory
      };
    }
  });

  return result;
};

export const parseNumber = amount => {
  if (amount === undefined || isNaN(amount)) {
    return '0.00';
  }
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });
};

export function formatNumberForCurrency(value, currencySign = '$') {
  let newValue;
  if (typeof value === 'string') {
    newValue = parseFloat(value);
  } else if (typeof value === 'number') {
    newValue = value;
  }
  if (isNaN(newValue)) {
    return '-';
  } else if (newValue < 0) {
    const absoluteValue = Math.abs(newValue);
    return `-${currencySign}${parseNumber(absoluteValue)}`;
  } else {
    return `${currencySign}${parseNumber(newValue)}`;
  }
}

export const sortArrayByDateField = (array, field) => {
  return _.sortBy(array, tx => new Date(tx?.[field])).reverse();
};

export const getCurrentClient = () => {
  return process.env.REACT_APP_CLIENT;
};

export const getClientMetaData = () => {
  let metadata = {
    title: '',
    siteUrl: ''
  };

  const client = process.env.REACT_APP_CLIENT;

  switch (client) {
    case 'betbigger':
      metadata.title = 'Betbigger';
      metadata.siteUrl = 'https://betbigger.com';
      break;
    case 'mysportsfunding':
      metadata.title = 'My Sports Funding';
      metadata.siteUrl = 'https://mysportsfunding.com/';
      break;
    case 'thefundedpick':
      metadata.title = 'The FundedPick';
      metadata.siteUrl = 'https://thefundedpick.com/';
      break;
    case 'sportsfunded':
      metadata.title = 'Sports Funded';
      metadata.siteUrl = 'https://sportsfunded.io/';
      break;
    default:
      metadata.title = '';
      metadata.siteUrl = '';
  }

  return metadata;
};

export const LOCALE_DEFAULT = 'en-US';
export const CURRENCY_CODE = 'USD';
export const CURRENCY_SYMBOL = '$';

export function formatValueToCurrency(value) {
  let newValue;
  if (typeof value === 'string') {
    newValue = parseFloat(value);
  } else if (typeof value === 'number') {
    newValue = value;
  }

  if (isNaN(newValue)) {
    return '-';
  } else if (newValue < 0) {
    const absoluteValue = Math.abs(newValue);
    return new Intl.NumberFormat(LOCALE_DEFAULT, {
      style: 'currency',
      currency: CURRENCY_CODE
    }).format(absoluteValue);
  } else {
    return new Intl.NumberFormat(LOCALE_DEFAULT, {
      style: 'currency',
      currency: CURRENCY_CODE
    }).format(newValue);
  }
}

export const getSportHeadersForClient = () => {
  switch (getCurrentClient()) {
    case 'mysportsfunding':
      return ['Handicap', 'Moneyline', 'Over/Under'];
  }

  return ['Spreads', 'Moneyline', 'Total'];
};
