import React from 'react';
import blueLogo from '../assets/blu_logo.png';
import gradientLogo from '../assets/gradient_logo.png';
import greenLogo from '../assets/green_logo.png';
import orangeLogo from '../assets/orange_logo.png';
import yellowLogo from '../assets/yellow_logo.png';
import betbiggerLogo from '../assets/betbigger/logo.png';
import betbiggerLogoSVG from '../assets/betbigger/logo.svg';
import mysportsfundingLogoPNG from '../assets/mysportsfunding/logo.png';
import thefundedpickLogoPNG from '../assets/thefundedpick/logo.png';
import sportsfundedLogoPNG from '../assets/sportsfunded/logo.png';
import betvaultLogoPNG from '../assets/betvault/logo.png';

import { myTheme } from '../theme';
import { Client, getCurrentClient } from '../../utils/general';

/**
 * Gets only the logo URL for a specific client
 * @returns {string} Logo URL
 */
export const getClientLogoUrl = () => {
  const client = getCurrentClient();

  if (client === Client.BETBIGGER) return betbiggerLogoSVG;
  if (client === Client.MYSPORTSFUNDING) return mysportsfundingLogoPNG;
  if (client === Client.THEFUNDEDPICK) return thefundedpickLogoPNG;
  if (client === Client.BETVAULT) return betvaultLogoPNG;
  if (client === Client.SPORTSFUNDED) return sportsfundedLogoPNG;
};

const Logo = () => {
  const client = getCurrentClient();

  const source = () => {
    let imageConfig = {
      src: '',
      height: '70px',
      width: '100px',
      type: 'image'
    };

    if (client === Client.BETBIGGER)
      return { width: '150px', height: '100px', src: betbiggerLogoSVG, type: 'image' };
    if (client === Client.MYSPORTSFUNDING)
      return { width: '100px', height: '50px', src: mysportsfundingLogoPNG, type: 'image' };
    if (client === Client.THEFUNDEDPICK)
      return {
        width: '100px',
        height: '50px',
        src: thefundedpickLogoPNG,
        type: 'text',
        text: 'The FundedPick'
      };
    if (client === Client.BETVAULT)
      return {
        width: '100px',
        height: '50px',
        src: betvaultLogoPNG,
        type: 'image',
        text: 'BetVault'
      };
    if (client === Client.SPORTSFUNDED)
      return { width: '100px', height: '50px', src: sportsfundedLogoPNG, type: 'image' };

    switch (myTheme) {
      case 1:
        return { ...imageConfig, src: blueLogo };
      case 2:
        return { ...imageConfig, src: orangeLogo };
      case 3:
        return { ...imageConfig, src: greenLogo };
      case 4:
        return { ...imageConfig, src: gradientLogo };
      case 5:
        return { ...imageConfig, src: yellowLogo };
      default:
        return { ...imageConfig, src: blueLogo };
    }
  };

  const image = source();

  return image?.type === 'image' ? (
    <img
      src={image.src}
      style={{ height: image.height, width: image.width }}
      alt='Logo'
      objectFit='cover'
    />
  ) : (
    <h1
      style={{ color: 'white', wordBreak: 'keep-all', whiteSpace: 'nowrap', fontWeight: 'bold' }}
    >
      {image.text}
    </h1>
  );
};

export default Logo;
