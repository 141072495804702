import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Alert, Button, Typography } from '@mui/material';
import ReactstrapFormikInput from '../../components/forms/ReactstrapFormikInput';
import './login-styles.css';
import Logo from '../../v2/components/Logo';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { stringContainsSpace } from '../../utils/general';
import { useResetPassword } from '../../react-query/user';
import { errorMessage, successMessage } from '../../helpers/Utils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import theme, { myTheme } from '../../v2/theme';

const Container = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#15151A'
}));

const LoginBox = styled('div')(({ theme }) => ({
  backgroundColor: '#1f1f1f',
  borderRadius: 10,
  minWidth: 356,
  padding: theme.spacing(4)
}));

const InnerBox = styled('div')(({ theme }) => ({
  backgroundColor: '#000',
  border: '1px solid #2a2a2a',
  borderRadius: 10,
  padding: theme.spacing(4)
}));

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\W_]/, 'Must contain at least one special character')
    .test('no-spaces', 'Cannot contain spaces', value => !/\s/.test(value)) // Prevents spaces anywhere
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required')
});

const PasswordResetConfirm = ({ onStateChange, loginUser, loading, error }) => {
  const { mutate } = useResetPassword();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const uid = query.get('uid');

  const handleResetPassword = values => {
    const { password, confirmPassword } = values;

    try {
      mutate(
        { uid: uid, token: token, new_password1: password, new_password2: confirmPassword },
        {
          onSuccess: data => {
            successMessage('Your password is reset');
            window.location.href = '/user/login';
          },
          onError: data => {
            errorMessage('Please try again');
          }
        }
      );
    } catch (error) {
      console.log('⏩ error:', error);
    }
  };

  return (
    <Container>
      <LoginBox>
        <InnerBox>
          <div style={{ textAlign: 'center', marginBottom: 24 }}>
            <Logo />
          </div>
          <Typography
            variant='h5'
            style={{ color: '#fff', textAlign: 'center', marginBottom: 16 }}
          >
            Reset your password
          </Typography>
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={passwordValidationSchema}
            onSubmit={handleResetPassword}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div>
                  <span style={{ color: '#FFF', fontFamily: 'Montserrat, Arial, sans-serif' }}>
                    Password
                  </span>
                  <Field
                    component={ReactstrapFormikInput}
                    type='password'
                    name='password'
                    className='mb-2'
                  />
                  <div style={{ color: '#fff', fontSize: '0.85rem' }}>
                    <PasswordRequirement
                      label='At least 8 characters'
                      valid={values.password.length >= 8}
                    />
                    <PasswordRequirement
                      label='At least one uppercase letter'
                      valid={/[A-Z]/.test(values.password)}
                    />
                    <PasswordRequirement
                      label='At least one number'
                      valid={/[0-9]/.test(values.password)}
                    />
                    <PasswordRequirement
                      label='At least one special character'
                      valid={/[\W_]/.test(values.password)}
                    />
                  </div>
                </div>

                <div style={{ marginTop: 10 }}>
                  <span style={{ color: '#FFF', fontFamily: 'Montserrat, Arial, sans-serif' }}>
                    Confirm Password
                  </span>
                  <Field
                    component={ReactstrapFormikInput}
                    type='password'
                    name='confirmPassword'
                    className='mb-2'
                  />
                  {values.confirmPassword && values.confirmPassword === values.password ? (
                    <div
                      style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '5px' }}
                    >
                      <CheckCircle style={{ fontSize: '16px' }} />
                      Passwords match
                    </div>
                  ) : values.confirmPassword ? (
                    <div
                      style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '5px' }}
                    >
                      <Cancel style={{ fontSize: '16px' }} />
                      Passwords do not match
                    </div>
                  ) : null}
                </div>

                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  style={{
                    borderRadius: 10,
                    marginTop: 16,
                    backgroundColor: theme(myTheme).palette.primary.main
                  }}
                  size='large'
                  type='submit'
                  disabled={loading}
                >
                  Reset your password
                </Button>
              </Form>
            )}
          </Formik>
        </InnerBox>
      </LoginBox>
    </Container>
  );
};

const PasswordRequirement = ({ label, valid }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
    {valid ? (
      <CheckCircle style={{ color: 'green', fontSize: '16px' }} />
    ) : (
      <Cancel style={{ color: 'red', fontSize: '16px' }} />
    )}
    {label}
  </div>
);

export default PasswordResetConfirm;
