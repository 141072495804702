import React from 'react';

const TrendDownRed = () => {
  return (
    <svg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.1663 14.8335V21.5001C25.1663 21.7212 25.0785 21.9331 24.9222 22.0894C24.766 22.2457 24.554 22.3335 24.333 22.3335H17.6663C17.4453 22.3335 17.2333 22.2457 17.0771 22.0894C16.9208 21.9331 16.833 21.7212 16.833 21.5001C16.833 21.2791 16.9208 21.0672 17.0771 20.9109C17.2333 20.7546 17.4453 20.6668 17.6663 20.6668H22.3215L14.333 12.6783L10.7559 16.2564C10.6785 16.3339 10.5866 16.3953 10.4854 16.4373C10.3843 16.4792 10.2758 16.5008 10.1663 16.5008C10.0568 16.5008 9.94836 16.4792 9.8472 16.4373C9.74603 16.3953 9.65413 16.3339 9.57673 16.2564L2.07673 8.75639C1.92037 8.60002 1.83252 8.38794 1.83252 8.1668C1.83252 7.94567 1.92037 7.73359 2.07673 7.57722C2.2331 7.42085 2.44518 7.33301 2.66632 7.33301C2.88745 7.33301 3.09953 7.42085 3.2559 7.57722L10.1663 14.4887L13.7434 10.9106C13.8208 10.8331 13.9127 10.7716 14.0139 10.7297C14.115 10.6877 14.2235 10.6661 14.333 10.6661C14.4425 10.6661 14.5509 10.6877 14.6521 10.7297C14.7533 10.7716 14.8452 10.8331 14.9226 10.9106L23.4997 19.4887V14.8335C23.4997 14.6125 23.5874 14.4005 23.7437 14.2442C23.9 14.0879 24.112 14.0001 24.333 14.0001C24.554 14.0001 24.766 14.0879 24.9222 14.2442C25.0785 14.4005 25.1663 14.6125 25.1663 14.8335Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default TrendDownRed;
