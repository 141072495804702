import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import BaseballIcon from './icons/BaseballIcon';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import SportsMmaIcon from '@material-ui/icons/SportsMma';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import SportsRugbyIcon from '@material-ui/icons/SportsRugby';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import HorseLogo from './icons/HorseLogo';
import TennisIcon from './icons/TennisIcon';
import InsightIcon from './icons/InsightIcon';

export const stringToDollar = val => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return USDollar.format(val);
};

export const getDecimalValue = value => {
  if (value === '-') {
    return '-';
  }
  if (value > 0) {
    return (value / 100 + 1).toFixed(2);
  } else {
    return (100 / Math.abs(value) + 1).toFixed(2);
  }
};

// export const sports = [
//   {
//     Icon: HomeIcon,
//     title: 'Home',
//     isCard: false
//   },
//   {
//     Icon: (
//       <div style={{ color: 'white' }}>
//         <InsightIcon />
//       </div>
//     ), // FIX: Fix this
//     title: 'My Stats',
//     isCard: false
//   }
// ];

export const sports = [
  {
    Icon: (
      <>
        <HomeIcon color='primary' />
      </>
    ),
    title: 'Home',
    isCard: false
  },
  {
    Icon: SportsFootballIcon,
    title: 'American Football',
    leagues: [
      {
        title: 'NFL',
        isCard: true,
        playerImg: 'players/nfl_player.png',
        sportImg: 'sports/nfl_logo.png',
        cardName: 'NFL'
      },
      {
        title: 'NFL Super Bowl Winner',
        isCard: false,
        playerImg: 'players/nfl_superbowl_player.png',
        sportImg: 'sports/nfl_superbowl_logo.png',
        cardName: 'NFL Super Bowl Winner'
      },
      {
        title: 'NCAAF',
        isCard: true,
        playerImg: 'players/ncaaf_player.png',
        sportImg: 'sports/ncaaf_logo.png',
        cardName: 'NCAAF'
      },
      {
        title: 'NCAAF Championship Winner',
        isCard: false,
        playerImg: 'players/ncaaf_championship_player.png',
        sportImg: 'sports/ncaaf_championship_logo.png',
        cardName: 'NCAAF Championship Winner'
      },
      {
        title: 'CFL',
        isCard: false,
        playerImg: 'players/cfl_player.png',
        sportImg: 'sports/cfl_logo.png',
        cardName: 'CFL'
      }
    ]
  },
  {
    Icon: SportsBasketballIcon,
    title: 'Basketball',
    leagues: [
      {
        title: 'NBA',
        isCard: true,
        playerImg: 'players/nba_player.png',
        sportImg: 'sports/nba_logo.png',
        cardName: 'NBA'
      },
      {
        title: 'NBA Championship Winner',
        isCard: false,
        playerImg: 'players/nba_championship_player.png',
        sportImg: 'sports/nba_championship_logo.png',
        cardName: 'NBA Championship Winner'
      },
      {
        title: 'NCAAB',
        isCard: true,
        playerImg: 'players/ncaab_player.png',
        sportImg: 'sports/nba_logo.png',
        cardName: 'NCAAB'
      },
      {
        title: 'NCAAB Championship Winner',
        isCard: false,
        playerImg: 'players/ncaab_championship_player.png',
        sportImg: 'sports/ncaab_championship_logo.png',
        cardName: 'NCAAB Championship Winner'
      },
      {
        title: 'Euroleague',
        isCard: false,
        playerImg: 'players/basketball_euroleague_player.png',
        sportImg: 'sports/basketball_euroleague_logo.png',
        cardName: 'Basketball Euroleague'
      }
    ]
  },
  {
    Icon: BaseballIcon,
    title: 'Baseball',
    leagues: [
      {
        title: 'MLB',
        isCard: true,
        playerImg: 'players/mlb_player.png',
        sportImg: 'sports/mlb_logo.png',
        cardName: 'MLB'
      },
      {
        title: 'MLB World Series Winner',
        isCard: false,
        playerImg: 'players/mlb_worldseries_player.png',
        sportImg: 'sports/mlb_worldseries_logo.png',
        cardName: 'MLB World Series Winner'
      },
      {
        title: 'NPB',
        isCard: false,
        playerImg: 'players/npb_player.png',
        sportImg: 'sports/npb_logo.png',
        cardName: 'NPB'
      }
    ]
  },
  {
    Icon: SportsHockeyIcon,
    title: 'Ice Hockey',
    leagues: [
      {
        title: 'NHL',
        isCard: true,
        playerImg: 'players/nhl_player.png',
        sportImg: 'sports/nhl_logo.png',
        cardName: 'NHL'
      },
      {
        title: 'NHL Championship Winner',
        isCard: false,
        playerImg: 'players/nhl_championship_player.png',
        sportImg: 'sports/nhl_championship_logo.png',
        cardName: 'NHL Championship Winner'
      }
    ]
  },
  {
    Icon: SportsSoccerIcon,
    title: 'Soccer',
    leagues: [
      {
        title: 'MLS',
        isCard: true,
        playerImg: 'players/mls_player.png',
        sportImg: 'sports/mls_logo.png',
        cardName: 'MLS'
      },
      {
        title: 'EPL',
        isCard: true,
        playerImg: 'players/epl_player.png',
        sportImg: 'sports/epl_logo.png',
        cardName: 'EPL'
      },
      {
        title: 'La Liga',
        isCard: true,
        playerImg: 'players/liga_player.png',
        sportImg: 'sports/laliga_logo.png',
        cardName: 'La Liga'
      },
      {
        title: 'Championship',
        isCard: false,
        playerImg: 'players/championship_player.png',
        sportImg: 'sports/championship_logo.png',
        cardName: 'Championship'
      },
      {
        title: 'League 1',
        isCard: false,
        playerImg: 'players/league1_player.png',
        sportImg: 'sports/league1_logo.png',
        cardName: 'League 1'
      },
      {
        title: 'League 2',
        isCard: false,
        playerImg: 'players/league2_player.png',
        sportImg: 'sports/league2_logo.png',
        cardName: 'League 2'
      },
      {
        title: 'Premiership - Scotland',
        isCard: false,
        playerImg: 'players/scotland_premiership_player.png',
        sportImg: 'sports/scotland_premiership_logo.png',
        cardName: 'Premiership - Scotland'
      },
      {
        title: 'Ligue 1 - France',
        isCard: false,
        playerImg: 'players/ligue1_player.png',
        sportImg: 'sports/ligue1_logo.png',
        cardName: 'Ligue 1 - France'
      },
      {
        title: 'Ligue 2 - France',
        isCard: false,
        playerImg: 'players/ligue2_player.png',
        sportImg: 'sports/ligue2_logo.png',
        cardName: 'Ligue 2 - France'
      },
      {
        title: 'Bundesliga - Germany',
        isCard: false,
        playerImg: 'players/bundesliga_player.png',
        sportImg: 'sports/bundesliga_logo.png',
        cardName: 'Bundesliga - Germany'
      },
      {
        title: 'Bundesliga 2 - Germany',
        isCard: false,
        playerImg: 'players/bundesliga2_player.png',
        sportImg: 'sports/bundesliga2_logo.png',
        cardName: 'Bundesliga 2 - Germany'
      },
      {
        title: 'Super League - Greece',
        isCard: false,
        playerImg: 'players/greece_superleague_player.png',
        sportImg: 'sports/greece_superleague_logo.png',
        cardName: 'Super League - Greece'
      },
      {
        title: 'Serie A - Italy',
        isCard: false,
        playerImg: 'players/seriea_player.png',
        sportImg: 'sports/seriea_logo.png',
        cardName: 'Serie A - Italy'
      },
      {
        title: 'Serie B - Italy',
        isCard: false,
        playerImg: 'players/serieb_player.png',
        sportImg: 'sports/serieb_logo.png',
        cardName: 'Serie B - Italy'
      },
      {
        title: 'League of Ireland',
        isCard: false,
        playerImg: 'players/ireland_league_player.png',
        sportImg: 'sports/ireland_league_logo.png',
        cardName: 'League of Ireland'
      },
      {
        title: 'Liga MX',
        isCard: false,
        playerImg: 'players/ligamx_player.png',
        sportImg: 'sports/ligamx_logo.png',
        cardName: 'Liga MX'
      },
      {
        title: 'Dutch Eredivisie',
        isCard: false,
        playerImg: 'players/eredivisie_player.png',
        sportImg: 'sports/eredivisie_logo.png',
        cardName: 'Dutch Eredivisie'
      },
      {
        title: 'Austrian Football Bundesliga',
        isCard: false,
        playerImg: 'players/austria_bundesliga_player.png',
        sportImg: 'sports/austria_bundesliga_logo.png',
        cardName: 'Austrian Football Bundesliga'
      },
      {
        title: 'Belgium First Div',
        isCard: false,
        playerImg: 'players/belgium_firstdiv_player.png',
        sportImg: 'sports/belgium_firstdiv_logo.png',
        cardName: 'Belgium First Div'
      },
      {
        title: 'Denmark Superliga',
        isCard: false,
        playerImg: 'players/denmark_superliga_player.png',
        sportImg: 'sports/denmark_superliga_logo.png',
        cardName: 'Denmark Superliga'
      },
      {
        title: 'Primeira Liga - Portugal',
        isCard: false,
        playerImg: 'players/portugal_primeiraliga_player.png',
        sportImg: 'sports/portugal_primeiraliga_logo.png',
        cardName: 'Primeira Liga - Portugal'
      },
      {
        title: 'La Liga 2 - Spain',
        isCard: false,
        playerImg: 'players/laliga2_player.png',
        sportImg: 'sports/laliga2_logo.png',
        cardName: 'La Liga 2 - Spain'
      },
      {
        title: 'Turkey Super League',
        isCard: false,
        playerImg: 'players/turkey_superleague_player.png',
        sportImg: 'sports/turkey_superleague_logo.png',
        cardName: 'Turkey Super League'
      },
      {
        title: 'Veikkausliiga - Finland',
        isCard: false,
        playerImg: 'players/finland_veikkausliiga_player.png',
        sportImg: 'sports/finland_veikkausliiga_logo.png',
        cardName: 'Veikkausliiga - Finland'
      },
      {
        title: 'Swiss Superleague',
        isCard: false,
        playerImg: 'players/swiss_superleague_player.png',
        sportImg: 'sports/swiss_superleague_logo.png',
        cardName: 'Swiss Superleague'
      },
      {
        title: 'Allsvenskan - Sweden',
        isCard: false,
        playerImg: 'players/sweden_allsvenskan_player.png',
        sportImg: 'sports/sweden_allsvenskan_logo.png',
        cardName: 'Allsvenskan - Sweden'
      },
      {
        title: 'Eliteserien - Norway',
        isCard: false,
        playerImg: 'players/norway_eliteserien_player.png',
        sportImg: 'sports/norway_eliteserien_logo.png',
        cardName: 'Eliteserien - Norway'
      },
      {
        title: 'Ekstraklasa - Poland',
        isCard: false,
        playerImg: 'players/poland_ekstraklasa_player.png',
        sportImg: 'sports/poland_ekstraklasa_logo.png',
        cardName: 'Ekstraklasa - Poland'
      },
      {
        title: 'UEFA Champions League',
        isCard: false,
        playerImg: 'players/ucl_player.png',
        sportImg: 'sports/ucl_logo.png',
        cardName: 'UEFA Champions League'
      },
      {
        title: 'UEFA Nations League',
        isCard: false,
        playerImg: 'players/nations_league_player.png',
        sportImg: 'sports/nations_league_logo.png',
        cardName: 'UEFA Nations League'
      },
      {
        title: 'A-League',
        isCard: false,
        playerImg: 'players/aleague_player.png',
        sportImg: 'sports/aleague_logo.png',
        cardName: 'A-League'
      },
      {
        title: 'Primera División - Argentina',
        isCard: false,
        playerImg: 'players/argentina_primera_player.png',
        sportImg: 'sports/argentina_primera_logo.png',
        cardName: 'Primera División - Argentina'
      },
      {
        title: 'Brazil Série A',
        isCard: false,
        playerImg: 'players/brazil_seriea_player.png',
        sportImg: 'sports/brazil_seriea_logo.png',
        cardName: 'Brazil Série A'
      },
      {
        title: 'Brazil Série B',
        isCard: false,
        playerImg: 'players/brazil_serieb_player.png',
        sportImg: 'sports/brazil_serieb_logo.png',
        cardName: 'Brazil Série B'
      },
      {
        title: 'Primera División - Chile',
        isCard: false,
        playerImg: 'players/chile_primera_player.png',
        sportImg: 'sports/chile_primera_logo.png',
        cardName: 'Primera División - Chile'
      }
    ]
  },
  {
    Icon: GolfCourseIcon,
    title: 'Golf',
    leagues: [
      {
        title: 'Masters Tournament Winner',
        isCard: false,
        playerImg: 'players/golf_masters_player.png',
        sportImg: 'sports/golf_masters_logo.png',
        cardName: 'Masters Tournament Winner'
      },
      {
        title: 'PGA Championship Winner',
        isCard: false,
        playerImg: 'players/golf_pga_player.png',
        sportImg: 'sports/golf_pga_logo.png',
        cardName: 'PGA Championship Winner'
      }
    ]
  },
  {
    Icon: SportsMmaIcon,
    title: 'Mixed Martial Arts and Boxing',
    leagues: [
      {
        title: 'MMA',
        isCard: true,
        playerImg: 'players/mma_player.png',
        sportImg: 'sports/mma_logo.png',
        cardName: 'MMA'
      },
      {
        title: 'Boxing',
        isCard: false,
        playerImg: 'players/boxing_player.png',
        sportImg: 'sports/boxing_logo.png',
        cardName: 'Boxing'
      }
    ]
  },
  {
    Icon: SportsCricketIcon,
    title: 'Cricket',
    leagues: [
      {
        title: 'Test Matches',
        isCard: false,
        playerImg: 'players/cricket_test_player.png',
        sportImg: 'sports/cricket_test_logo.png',
        cardName: 'Test Matches'
      },
      {
        title: 'One Day Internationals',
        isCard: false,
        playerImg: 'players/cricket_odi_player.png',
        sportImg: 'sports/cricket_odi_logo.png',
        cardName: 'One Day Internationals'
      },
      {
        title: 'International Twenty20',
        isCard: false,
        playerImg: 'players/cricket_t20_player.png',
        sportImg: 'sports/cricket_t20_logo.png',
        cardName: 'International Twenty20'
      },
      {
        title: 'CPLT20',
        isCard: false,
        playerImg: 'players/cricket_cplt20_player.png',
        sportImg: 'sports/cricket_cplt20_logo.png',
        cardName: 'CPLT20'
      }
    ]
  },
  {
    Icon: SportsRugbyIcon,
    title: 'Rugby League',
    leagues: [
      {
        title: 'NRL',
        isCard: false,
        playerImg: 'players/nrl_player.png',
        sportImg: 'sports/nrl_logo.png',
        cardName: 'NRL'
      }
    ]
  }
];

export const sportIconMap = {
  Home: HomeIcon,
  'American Football': SportsFootballIcon,
  Basketball: SportsBasketballIcon,
  Baseball: BaseballIcon,
  'Ice Hockey': SportsHockeyIcon,
  'Mixed Martial Arts and Boxing': SportsMmaIcon,
  MMA: SportsMmaIcon,
  Boxing: SportsMmaIcon,
  Soccer: SportsSoccerIcon,
  Golf: GolfCourseIcon,
  Cricket: SportsCricketIcon,
  'Rugby League': SportsRugbyIcon,
  Rugby: SportsRugbyIcon,
  'Horse Racing': HorseLogo,
  Tennis: TennisIcon
};

export function formatNumberWithNegativeSign(value) {
  let newValue;
  if (typeof value === 'string') {
    newValue = parseFloat(value);
  } else if (typeof value === 'number') {
    newValue = value;
  }
  if (isNaN(newValue)) {
    return '-';
  } else if (newValue < 0) {
    const absoluteValue = Math.abs(newValue);
    return `-£${parseNumber(absoluteValue)}`;
  } else {
    return `£${parseNumber(newValue)}`;
  }
}

export const parseNumber = amount => {
  if (amount === undefined || isNaN(amount)) {
    return '0.00';
  }
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });
};
