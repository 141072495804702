import { api } from '../api/index';

export const GLOBAL_API_STALE_TIME = 30 * 100;
export const GLOBAL_API_REFETCH_INTERVAL = 30 * 100;

export const GlobalQuery = {
  UserBets: 'user-bets',
  UserInfo: 'userinfo',
  BettingAccountTransaction: 'betting-account-transactions',
  PayoutsHistory: 'payout-history'
};
