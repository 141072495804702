import { makeStyles } from '@material-ui/core';
import React from 'react';
import { formatNumberForCurrency } from '../../../utils/general';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1), // 4px
    padding: theme.spacing(2.5), // 20px
    width: '100%',
    borderRadius: '8px', // assuming 8px default => 12px
    background: theme.palette.custom.betButton,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // shadow-dashboard-icon-shadow
    border: `1px solid ${theme.palette.custom.inputBorder}`, // border-primary-iconBorder
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  iconContainer: {
    height: theme.spacing(6), // 48px
    width: theme.spacing(6), // 48px
    background: theme.palette.custom.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2 // assuming 8px default => 16px
  },
  cardTitle: {
    color: theme.palette.custom.white, // text-primary-default
    fontSize: theme.typography.pxToRem(14) // text-sm
  },
  cardValue: {
    fontSize: theme.typography.pxToRem(20) // text-xl
  },
  cardValuePositive: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.success.main // or your specific green color
  },
  cardValueNegative: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.error.main // or your specific red color
  },
  cardValueNeutral: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.white
  }
}));

const TopCard = ({ card }) => {
  const Icon = card.icon;
  const classes = useStyles();

  const getValueColor = value => {
    if (card?.defaultType === 'positive') return classes.cardValuePositive;
    if (card?.defaultType === 'negative') return classes.cardValueNegative;
    if (!value || value === '-') return classes.cardValueNeutral;
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return classes.cardValueNeutral;
    return numValue > 0 ? classes.cardValuePositive : classes.cardValueNegative;
  };

  const formatValue = value => {
    if (card?.type === 'currency') {
      return formatNumberForCurrency(value);
    }

    if (card?.type === 'percentage') {
      return `%${card.value}`;
    }

    return value;
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.iconContainer}>
        <Icon />
      </div>
      <div>
        <div>
          <span className={classes.cardTitle}>{card?.title}</span>
        </div>
        <div>
          <span className={getValueColor(card?.value)}>{formatValue(card?.value)}</span>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
