import React from 'react';

const TrendUp = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.4167 6.49996V13.1666C25.4167 13.3876 25.3289 13.5996 25.1726 13.7559C25.0163 13.9122 24.8044 14 24.5834 14C24.3623 14 24.1504 13.9122 23.9941 13.7559C23.8378 13.5996 23.75 13.3876 23.75 13.1666V8.51142L15.1729 17.0895C15.0955 17.167 15.0036 17.2285 14.9025 17.2704C14.8013 17.3124 14.6929 17.3339 14.5833 17.3339C14.4738 17.3339 14.3654 17.3124 14.2642 17.2704C14.1631 17.2285 14.0712 17.167 13.9938 17.0895L10.4167 13.5114L3.50627 20.4229C3.3499 20.5792 3.13782 20.6671 2.91668 20.6671C2.69555 20.6671 2.48347 20.5792 2.3271 20.4229C2.17073 20.2665 2.08289 20.0544 2.08289 19.8333C2.08289 19.6122 2.17073 19.4001 2.3271 19.2437L9.8271 11.7437C9.90449 11.6662 9.9964 11.6048 10.0976 11.5628C10.1987 11.5209 10.3072 11.4993 10.4167 11.4993C10.5262 11.4993 10.6346 11.5209 10.7358 11.5628C10.837 11.6048 10.9289 11.6662 11.0063 11.7437L14.5833 15.3218L22.5719 7.33329H17.9167C17.6957 7.33329 17.4837 7.2455 17.3274 7.08921C17.1711 6.93293 17.0834 6.72097 17.0834 6.49996C17.0834 6.27895 17.1711 6.06698 17.3274 5.9107C17.4837 5.75442 17.6957 5.66663 17.9167 5.66663H24.5834C24.8044 5.66663 25.0163 5.75442 25.1726 5.9107C25.3289 6.06698 25.4167 6.27895 25.4167 6.49996Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default TrendUp;
