import { api } from '../api/index';

const QueryFunctions = {
  fetchUser: async () => {
    return await api.get(`/accounts/accounts/user-profile`);
  },
  /**
   * Fetches games for a specified league.
   * @param {string} league - The league to fetch games for.
   * @returns {Promise<any>} - A promise resolving to the games data.
   */
  fetchGames: async league => {
    return await api.get(`/api/sport/games?league=${league}`);
  },
  /**
   * Fetches All available Sports to show on Sidebar
   * @returns {Promise<any>} - A promise resolving to the games data.
   */
  fetchSports: async () => {
    return await api.get('/api/sport/available');
  },
  /**
   * Fetches User Bets
   * @param {string} currentBettingAccountId - The league to fetch games for.
   * @returns {Promise<any>} - A promise resolving data.
   */
  fetchUserbets: async currentBettingAccountId => {
    return await api.get(`/trade/${currentBettingAccountId}/user-bets/`);
  },

  /**
   * Fetches User Payout History
   * @param {string} currentBettingAccountId - The league to fetch games for.
   * @returns {Promise<any>} - A promise resolving data.
   */
  fetchUserPayoutHistory: async () => {
    return await api.get(`/payment/withdrawals/?date_filter=All`);
  },
  /**
   * Fetches User Transactions
   * @param {string} currentBettingAccountId - The league to fetch games for.
   * @returns {Promise<any>} - A promise resolving data.
   */
  fetchUserBettingTransactions: async currentBettingAccountId => {
    return await api.get(`/me/betting-account/${currentBettingAccountId}/transactions`);
  },
  /**
   * Fetches User Transactions
   * @param {string} currentBettingAccountId - The league to fetch games for.
   * @returns {Promise<any>} - A promise resolving data.
   */
  fetchUserBettingTransactions: async currentBettingAccountId => {
    //return await api.get(`/me/betting-account/${currentBettingAccountId}/transactions`);
    return await api.get(`/trade/balancehistory/my-balancehistory/${currentBettingAccountId}/`);
  },
  sendResetPasswordLink: async email => {
    return await api.post(`/authentication/password-reset/`, { email });
  },
  resetPassword: async ({ new_password1, new_password2, token, uid }) => {
    return await api.post(`/authentication/password-reset-confirm/`, {
      new_password1,
      new_password2,
      uid,
      token
    });
  },
  fetchAccountStats: async currentBettingAccountId => {
    return await api.get(`/accounts/accounts/${currentBettingAccountId}/statistics`);
  },
  fetchAccounts: async () => {
    return await api.get(`/api/accounts/accounts/my-accounts/`);
  }
};

export default QueryFunctions;
