import { useDispatch, useSelector } from 'react-redux';
import { setBetList } from '../redux/bets/actions';
import { betList } from '../redux/bets/selector';

const useBetList = () => {
  const userBets = useSelector(betList);
  const dispatch = useDispatch();
  const handleBetList = bets => {
    dispatch(setBetList(bets));
  };

  return {
    onSetBetList: handleBetList,
    parlayBets: userBets?.parlayBets,
    singleBets: userBets?.singleBets
  };
};

export default useBetList;
