import { useDispatch, useSelector } from 'react-redux';
import { setTransactions } from '../redux/transactions/actions';
import { transactionSelector } from '../redux/transactions/selector';

function useTransactions() {
  const dispatch = useDispatch();
  const transactions = useSelector(transactionSelector);

  const handleSetTransactions = txns => {
    dispatch(setTransactions(txns));
  };

  return {
    setTransactions: handleSetTransactions,
    transactions
  };
}

export default useTransactions;
