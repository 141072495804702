import React, { useState } from 'react';
// import ReactPaginate from 'react-paginate';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '../icons/SearchIcon';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2, // rounded-2xl
    background: theme.palette.custom.betButton
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column', // flex-col
    gap: theme.spacing(0.5), // 8px (gap-2)
    padding: theme.spacing(2), // p-5
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row', // md:flex-row
      alignItems: 'center' // md:items-center
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between' // lg:justify-between
    }
  },
  title: {
    fontSize: theme.typography.pxToRem(20), // text-xl
    fontWeight: theme.typography.fontWeightMedium, // font-medium
    color: theme.palette.custom.white // text-primary-text
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column', // flex-col
    gap: theme.spacing(1), // 12px (gap-3)
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row', // md:flex-row
      alignItems: 'center' // md:items-center
    }
  },
  dateInputContainer: {
    borderRadius: '8px !important',
    border: `1px solid ${theme.palette.custom.inputBorder}`,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(0.5, 1.5, 0.5, 3),
    background: theme.palette.custom.betButton,
    color: theme.palette.custom.white,
    padding: '10px 10px',
    '&::placeholder': {
      marginLeft: 20,
      color: theme.palette.custom.white // text-primary-default
    }
  },
  dateInput: {
    borderRadius: '8px !important',
    width: '100%',
    outline: 'none',
    border: 'none',
    paddingLeft: theme.spacing(2),
    background: theme.palette.custom.betButton,
    color: theme.palette.custom.white,
    '&::placeholder': {
      marginLeft: 20,
      color: theme.palette.custom.white // text-primary-default
    }
  },
  searchContainer: {
    position: 'relative',
    width: '100%'
  },
  searchIcon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1.5),
    top: '30%',
    left: 0,
    marginRight: 10,
    pointerEvents: 'none'
  },
  searchInput: {
    width: '100%',
    borderRadius: '8px', // rounded-lg
    border: `1px solid ${theme.palette.custom.inputBorder}`, // border-primary-iconBorder
    padding: theme.spacing(0.5, 1.5, 0.5, 3),
    outline: 'none',
    fontSize: theme.typography.pxToRem(14), // text-sm
    color: theme.palette.custom.white, // text-primary
    background: theme.palette.custom.betButton,
    paddingLeft: 40,
    paddingTop: 10,
    paddingBottom: 10,
    '&::placeholder': {
      marginLeft: 20,
      color: theme.palette.custom.white // text-primary-default
    }
  },
  tableContainer: {
    position: 'relative',
    overflowX: 'auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    '&::-webkit-scrollbar': {
      height: theme.spacing(0.5) // Tailwind h-1
    }
  },
  table: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse', // border-collapse
    fontSize: theme.typography.pxToRem(14) // text-sm
  },
  thead: {
    background: theme.palette.custom.betButton,
    fontSize: theme.typography.pxToRem(12), // text-xs
    fontWeight: theme.typography.fontWeightMedium, // font-medium
    color: theme.palette.custom.white
  },
  th: {
    padding: theme.spacing(2, 2), // py-3 px-4
    fontSize: theme.typography.pxToRem(12), // text-xs-medium
    textAlign: 'left',
    "&[align='right']": {
      textAlign: 'right'
    }
  },
  tbody: {
    backgroundColor: theme.palette.background.dark, // Tailwind bg-[#090e10]
    width: '100%'
  },
  tr: {
    color: theme.palette.custom.white, // text-primary-text
    borderBottom: `1px solid ${theme.palette.divider}`, // border-b border-table-border
    fontSize: theme.typography.pxToRem(14), // text-sm
    fontWeight: theme.typography.fontWeightRegular // font-normal
  },
  td: {
    padding: theme.spacing(1, 2), // px-4 py-4
    textAlign: 'left',
    "&[align='right']": {
      textAlign: 'right'
    }
  }
}));

const NewTable = ({ title, columns, data }) => {
  const classes = useStyles();

  function getPropertyValue(object, key) {
    const keys = key.split('.');
    let value = object;
    for (const k of keys) {
      if (value?.hasOwnProperty(k)) {
        value = value[k];
      } else {
        return undefined; // Property doesn't exist
      }
    }
    return value;
  }

  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.length / itemsPerPage);

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.title}>{title}</div>
          {/* <div className={classes.actionContainer}> */}
          {/*   <div className={classes.dateInputContainer}> */}
          {/*     <input type='date' className={classes.dateInput} /> */}
          {/*   </div> */}
          {/*   <div className={classes.searchContainer}> */}
          {/*     <div className={classes.searchIcon}> */}
          {/*       <SearchIcon /> */}
          {/*     </div> */}
          {/*     <input type='text' className={classes.searchInput} placeholder='Search' required /> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
        <div className='pt-4'>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              {columns && (
                <thead className={classes.thead}>
                  <tr>
                    {columns.map((column, index) =>
                      column.renderColumn ? (
                        <th
                          key={index}
                          align={column.name === 'Net Profit' ? 'right' : 'left'}
                          className={classes.th}
                        >
                          {column.renderColumn()}
                        </th>
                      ) : (
                        <th
                          key={index}
                          align={
                            column.name === 'Net Profit' ||
                            column.name === 'Download Win/Loss Card'
                              ? 'right'
                              : 'left'
                          }
                          className={classes.th}
                        >
                          {column.name}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
              )}
              <tbody className={classes.tbody}>
                {currentItems?.map((row, index) => (
                  <tr key={index} className={classes.tr}>
                    {columns.map((column, id) =>
                      column.renderCell ? (
                        <td key={id} className={classes.td}>
                          {column.renderCell(row)}
                        </td>
                      ) : (
                        <td key={id} className={classes.td}>
                          {getPropertyValue(row, column.key) || ''}
                        </td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className='flex items-center justify-end pt-9 pb-10 px-4'>
            <div className='flex items-center gap-4'>
              <div className='text-primary-text text-sm font-semibold'>First</div>
              <ReactPaginate
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                activeClassName={'active'}
                previousClassName={'previous-label'}
                nextClassName={'next-label'}
                breakLabel='...'
                nextLabel={
                  <div>
                    <CaretRight />
                  </div>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={
                  <div>
                    <CaretLeft />
                  </div>
                }
                renderOnZeroPageCount={null}
              />
              <div className='text-primary-text text-sm font-semibold'>Last</div>
            </div>
          </div> */}
        </div>
      </div>
      {/* table */}
    </div>
  );
};

export default NewTable;
