import createReducer from '../createReducer';
import { USER_INFO, CURRENT_BETTING_ACCOUNT_ID, USER_ACCOUNTS } from './actions';

const initialState = {
  userInfo: {
    currentBettingAccountId: null,
    bettingAccounts: [],
    userAccounts: []
  }
};

export default createReducer(initialState, {
  [USER_INFO]: (state, action) => ({
    ...state,
    userInfo: action.userInfo
  }),
  [CURRENT_BETTING_ACCOUNT_ID]: (state, action) => ({
    ...state,
    currentBettingAccountId: action.currentBettingAccountId
  }),
  [USER_ACCOUNTS]: (state, action) => {
    return {
      ...state,
      userAccounts: action.userAccounts
    };
  }
});
