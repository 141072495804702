import React from 'react';

const ProgressBar = ({ height, percentage, backgroundColor, accentColor }) => {
  const containerStyles = {
    height: height,
    width: '100%',
    backgroundColor: backgroundColor,
    borderRadius: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: accentColor,
    borderRadius: 50,
    textAlign: 'right'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} />
    </div>
  );
};

export default ProgressBar;
