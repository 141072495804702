import { api } from '../api/index';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GLOBAL_API_REFETCH_INTERVAL, GLOBAL_API_STALE_TIME, GlobalQuery } from './config';
import useUserInfo from '../v2/hooks/useUserInfo';
import { isEmpty } from 'lodash';
import { QueryClient } from 'react-query';
import { useEffect } from 'react';
import { QueryDataTypes } from '../types';
import QueryFunctions from './query-fns';

const queryClient = new QueryClient();

/**
 * Fetches the user data and betting accounts.
 * @returns {{
 *   refetch: () => void,
 *   data: QueryDataTypes.UserInfoData | undefined,
 *   error: string | undefined,
 *   isLoading: boolean,
 *   isError: boolean,
 *   isSuccess: boolean,
 * }} - The query object containing refetch, data, error, and isLoading.
 */
export const GetMe = () => {
  const { onSetUserInfo, setCurBettingAccountId, currentBettingAccountId } = useUserInfo();

  const { data, isError, error, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [GlobalQuery.UserInfo],
    queryFn: () => QueryFunctions.fetchUser(),
    staleTime: GLOBAL_API_STALE_TIME * 100,
    refetchInterval: GLOBAL_API_REFETCH_INTERVAL * 100
  });

  if (!isEmpty(data?.data?.user)) {
    onSetUserInfo(data.data.user);

    const curBetId = data.data.user?.bettingAccounts?.[0]?.id;
    if (currentBettingAccountId === null || currentBettingAccountId == undefined) {
      setCurBettingAccountId(curBetId);
    }
  }

  return {
    data: data?.data?.user,
    error: error?.response?.data?.message,
    refetch,
    isError,
    isSuccess,
    isLoading
  };
};

/**
 * Updates the user's first and last name.
 *
 * @param {Object} param0 - The new user information.
 * @param {string | null} param0.firstName - The new first name for the user.
 * @param {string | null} param0.lastName - The new last name for the user.
 * @returns {{
 *   mutate: Function,
 *   isLoading: boolean,
 *   isError: boolean,
 *   error: string | undefined,
 *   isSuccess: boolean,
 *   data: {
 *     message: string,
 *     user: {
 *       id: number,
 *       email: string,
 *       firstName: string | null,
 *       lastName: string | null,
 *       screenName: string | null
 *     }
 *   } | null
 * }} - The mutation object containing data, error, and loading status.
 */
export const UpdateMe = () => {
  const updateUserData = async data => {
    const res = await api.put(`/authentication/user/`, data);
    return res;
  };

  const { mutate, data, error, isError, isLoading, isSuccess } = useMutation({
    mutationFn: data => updateUserData(data)
  });

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries([GlobalQuery.UserBets]);
    }
  }, [isSuccess]);

  return {
    mutate,
    data: data?.data,
    error: error?.response?.data?.message,
    isError,
    isSuccess,
    isLoading
  };
};

/**
 * Updates the user's first and last name.
 *
 * @param {Object} param0 - The new user information. @param {string | null} param0.firstName - The new first name for the user.
 * @param {string | null} param0.lastName - The new last name for the user.
 * @returns {{
 *   mutate: Function,
 *   isLoading: boolean,
 *   isError: boolean,
 *   error: string | undefined,
 *   isSuccess: boolean,
 *   data: {
 *     message: string,
 *   } | null
 * }} - The mutation object containing data, error, and loading status.
 */
export const UpdateUserPassword = () => {
  const updatePassword = async data => {
    if (!data?.oldPassword) throw Error('Old password must be supplied');
    if (!data?.newPassword) throw Error('New password must be supplied');

    const res = await api.put(`/me/update-password`, data);
    return res;
  };

  const { mutate, data, error, isError, isLoading, isSuccess } = useMutation({
    mutationFn: data => updatePassword(data)
  });

  return {
    mutate,
    data: data?.data,
    error: error?.response?.data?.message,
    isError,
    isSuccess,
    isLoading
  };
};

/**
 * Updates the user's first and last name.
 *
 * @param {Object} param0 - The new user information. @param {string | null} param0.firstName - The new first name for the user.
 * @param {string | null} param0.lastName - The new last name for the user.
 * @returns {{
 *   mutate: Function,
 *   isLoading: boolean,
 *   isError: boolean,
 *   error: string | undefined,
 *   isSuccess: boolean,
 *   data: {
 *     message: string,
 *   } | null
 * }} - The mutation object containing data, error, and loading status.
 */
export const useSendResetPasswordLink = () => {
  return useMutation(async email => await QueryFunctions.sendResetPasswordLink(email));
};

/**
 * Updates the user's first and last name.
 *
 * @param {Object} param0 - The new user information. @param {string | null} param0.firstName - The new first name for the user.
 * @param {string | null} param0.lastName - The new last name for the user.
 * @returns {{
 *   mutate: Function,
 *   isLoading: boolean,
 *   isError: boolean,
 *   error: string | undefined,
 *   isSuccess: boolean,
 *   data: {
 *     message: string,
 *   } | null
 * }} - The mutation object containing data, error, and loading status.
 */
export const useResetPassword = () => {
  return useMutation(
    async ({ uid, token, new_password1, new_password2 }) =>
      await QueryFunctions.resetPassword({ uid, token, new_password1, new_password2 })
  );
};

/**
 * Gets the account statistics including general stats, challenge stats, and performance stats.
 *
 * @returns {{
 *   data: {
 *     general_stats: {
 *       initial_balance: string,
 *       profit_target: string,
 *       current_drawdown: number,
 *       max_allowed_drawdown: string,
 *       win_rate: number,
 *       bets_placed: number,
 *       total_winnings: number,
 *       pnl: number,
 *       roi: number
 *       live_balance: number
 *     },
 *     challenge_stats: {
 *       total_picks: number,
 *       winning_picks: number,
 *       losing_picks: number,
 *       picks_in_play: number,
 *       total_single_picks: number,
 *       total_parlay_picks: number
 *     },
 *     performance_stats: {
 *       total_winners: number,
 *       best_win: number,
 *       avg_win: number,
 *       max_win_streak: number,
 *       total_losers: number,
 *       worst_loss: number,
 *       avg_loss: number,
 *       max_loss_streak: number,
 *       profit_factor: number,
 *       average_rrr: number,
 *       total_winnings_amount: number,
 *       total_loss_amount: number,
 *       best_day: {
 *         date: string | null,
 *         pnl: number
 *       },
 *       worst_day: {
 *         date: string | null,
 *         pnl: number
 *       }
 *     }
 *   } | null,
 *   isLoading: boolean,
 *   isError: boolean,
 *   error: string | undefined,
 *   isSuccess: boolean,
 *   refetch: Function
 * }} - The query object containing stats data, error, and loading status.
 */
export const GetAccountStats = () => {
  const { currentBettingAccountId } = useUserInfo();

  const { data, isError, error, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [GlobalQuery.AccountStats, currentBettingAccountId],
    queryFn: () => QueryFunctions.fetchAccountStats(currentBettingAccountId),
    staleTime: GLOBAL_API_STALE_TIME * 100,
    refetchInterval: GLOBAL_API_REFETCH_INTERVAL * 100
  });

  return {
    data: data?.data,
    error: error?.response?.data?.message,
    refetch,
    isError,
    isSuccess,
    isLoading
  };
};

export const GetAllAccounts = () => {
  const { setUserAccounts } = useUserInfo();
  const { data, isError, error, isSuccess, isLoading, refetch } = useQuery({
    queryKey: [GlobalQuery.Challenges],
    queryFn: () => QueryFunctions.fetchAccounts(),
    staleTime: GLOBAL_API_STALE_TIME * 100,
    refetchInterval: GLOBAL_API_REFETCH_INTERVAL * 100
  });
  if (data?.data) {
    setUserAccounts(data?.data);
  }
  return {
    data: data?.data,
    error: error?.response?.data?.message,
    refetch,
    isError,
    isSuccess,
    isLoading
  };
};
