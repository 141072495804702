import React from 'react';

const ArrowDownRed = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.8394 16.2563L14.3394 23.7563C14.262 23.8337 14.1701 23.8952 14.0689 23.9371C13.9678 23.9791 13.8593 24.0007 13.7498 24.0007C13.6403 24.0007 13.5319 23.9791 13.4307 23.9371C13.3295 23.8952 13.2376 23.8337 13.1602 23.7563L5.66023 16.2563C5.50386 16.0999 5.41602 15.8878 5.41602 15.6667C5.41602 15.4455 5.50386 15.2335 5.66023 15.0771C5.8166 14.9207 6.02868 14.8329 6.24981 14.8329C6.47095 14.8329 6.68303 14.9207 6.8394 15.0771L12.9165 21.1552V4.83333C12.9165 4.61232 13.0043 4.40036 13.1606 4.24408C13.3168 4.0878 13.5288 4 13.7498 4C13.9708 4 14.1828 4.0878 14.3391 4.24408C14.4953 4.40036 14.5831 4.61232 14.5831 4.83333V21.1552L20.6602 15.0771C20.8166 14.9207 21.0287 14.8329 21.2498 14.8329C21.471 14.8329 21.683 14.9207 21.8394 15.0771C21.9958 15.2335 22.0836 15.4455 22.0836 15.6667C22.0836 15.8878 21.9958 16.0999 21.8394 16.2563Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ArrowDownRed;
