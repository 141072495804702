import React from 'react';

const Scales = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.8575 14.5208L22.5241 6.1875C22.4507 6.00394 22.3145 5.85246 22.1397 5.7601C21.9649 5.66775 21.763 5.64054 21.57 5.68333L15.0835 7.125V4.83333C15.0835 4.61232 14.9957 4.40036 14.8394 4.24408C14.6832 4.0878 14.4712 4 14.2502 4C14.0292 4 13.8172 4.0878 13.6609 4.24408C13.5046 4.40036 13.4168 4.61232 13.4168 4.83333V7.49792L6.56893 9.01979C6.4369 9.04896 6.31395 9.10981 6.21069 9.1971C6.10742 9.28439 6.02695 9.3955 5.97622 9.52083V9.52708L2.64289 17.8542C2.60301 17.9535 2.58284 18.0596 2.58351 18.1667C2.58351 20.5948 5.13976 21.5 6.75018 21.5C8.3606 21.5 10.9168 20.5948 10.9168 18.1667C10.9175 18.0596 10.8973 17.9535 10.8575 17.8542L7.88768 10.4344L13.4168 9.20833V22.3333H11.7502C11.5292 22.3333 11.3172 22.4211 11.1609 22.5774C11.0046 22.7337 10.9168 22.9457 10.9168 23.1667C10.9168 23.3877 11.0046 23.5996 11.1609 23.7559C11.3172 23.9122 11.5292 24 11.7502 24H16.7502C16.9712 24 17.1832 23.9122 17.3394 23.7559C17.4957 23.5996 17.5835 23.3877 17.5835 23.1667C17.5835 22.9457 17.4957 22.7337 17.3394 22.5774C17.1832 22.4211 16.9712 22.3333 16.7502 22.3333H15.0835V8.83542L20.396 7.65625L17.6429 14.5208C17.603 14.6202 17.5828 14.7263 17.5835 14.8333C17.5835 17.2615 20.1398 18.1667 21.7502 18.1667C23.3606 18.1667 25.9168 17.2615 25.9168 14.8333C25.9175 14.7263 25.8973 14.6202 25.8575 14.5208ZM6.75018 19.8333C5.9658 19.8333 4.37935 19.4573 4.25747 18.3083L6.75018 12.0771L9.24289 18.3083C9.12101 19.4573 7.53455 19.8333 6.75018 19.8333ZM21.7502 16.5C20.9658 16.5 19.3793 16.124 19.2575 14.975L21.7502 8.74375L24.2429 14.975C24.121 16.124 22.5346 16.5 21.7502 16.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Scales;
