import React from 'react';

const ArrowUpGreen = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.8394 12.9236C21.762 13.0011 21.6701 13.0625 21.5689 13.1045C21.4678 13.1464 21.3593 13.168 21.2498 13.168C21.1403 13.168 21.0319 13.1464 20.9307 13.1045C20.8295 13.0625 20.7376 13.0011 20.6602 12.9236L14.5831 6.84545V23.1673C14.5831 23.3883 14.4953 23.6003 14.3391 23.7566C14.1828 23.9129 13.9708 24.0007 13.7498 24.0007C13.5288 24.0007 13.3168 23.9129 13.1606 23.7566C13.0043 23.6003 12.9165 23.3883 12.9165 23.1673V6.84545L6.8394 12.9236C6.68303 13.0799 6.47095 13.1678 6.24981 13.1678C6.02868 13.1678 5.8166 13.0799 5.66023 12.9236C5.50386 12.7672 5.41602 12.5551 5.41602 12.334C5.41602 12.1129 5.50386 11.9008 5.66023 11.7444L13.1602 4.24441C13.2376 4.16693 13.3295 4.10546 13.4307 4.06352C13.5319 4.02159 13.6403 4 13.7498 4C13.8593 4 13.9678 4.02159 14.0689 4.06352C14.1701 4.10546 14.262 4.16693 14.3394 4.24441L21.8394 11.7444C21.9169 11.8218 21.9783 11.9137 22.0203 12.0149C22.0622 12.116 22.0838 12.2245 22.0838 12.334C22.0838 12.4435 22.0622 12.5519 22.0203 12.6531C21.9783 12.7543 21.9169 12.8462 21.8394 12.9236Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ArrowUpGreen;
