import React from 'react';

const BaseballIcon = props => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M15.9035 0.602051C15.4365 0.602051 14.992 0.786613 14.6606 1.11807L8.7282 7.05044C8.3327 7.44593 7.98241 7.88286 7.68485 8.35368L4.93148 12.7154C4.65652 13.1485 4.33636 13.5516 3.971 13.9169L2.56606 15.3181L4.49832 17.2504L5.90326 15.8454C6.26485 15.4838 6.66787 15.1599 7.1048 14.8849L11.4665 12.1316C11.9373 11.834 12.3742 11.4837 12.7697 11.0882L18.7021 5.15962C19.0298 4.83192 19.2181 4.3837 19.2181 3.91664C19.2181 3.44959 19.0336 3.00513 18.7021 2.67367L17.1465 1.11807C16.8151 0.786613 16.3706 0.602051 15.9035 0.602051ZM19.2181 16.8737C19.2181 16.0745 18.9007 15.3081 18.3356 14.743C17.7705 14.1779 17.004 13.8604 16.2049 13.8604C15.4057 13.8604 14.6393 14.1779 14.0742 14.743C13.5091 15.3081 13.1916 16.0745 13.1916 16.8737C13.1916 17.6729 13.5091 18.4393 14.0742 19.0044C14.6393 19.5695 15.4057 19.887 16.2049 19.887C17.004 19.887 17.7705 19.5695 18.3356 19.0044C18.9007 18.4393 19.2181 17.6729 19.2181 16.8737ZM0.49821 15.6307C0.144151 15.9848 0.144151 16.5573 0.49821 16.9076L2.90882 19.3182C3.26288 19.6723 3.8354 19.6723 4.1857 19.3182C4.53599 18.9641 4.53975 18.3916 4.1857 18.0413L1.77885 15.6307C1.42479 15.2767 0.852269 15.2767 0.501977 15.6307H0.49821Z'
        fill='white'
      />
    </svg>
  );
};

export default BaseballIcon;
