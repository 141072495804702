import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { clearPersistedMatches } from '../redux/matches/actions';

const Hijack = ({}) => {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      // Clearnup functions
      window.localStorage.clear();
      dispatch(clearPersistedMatches());

      window.localStorage.setItem('token', JSON.stringify(token));
      history.push('/app/dashboard');
    }
  }, [token, history]);

  return <div>Hijacking...</div>;
};

export default Hijack;
